import {
	Component,
	EventEmitter,
	Input,
	Output,
	OnInit,
} from '@angular/core';
import { Location } from '@angular/common'; 
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';

import * as fromStore from 'src/app/store/';
import { CreateTicketComponent } from 'src/app/tickets/containers';

import OrderInfoDetail from '../order-info.interface';
import OrderContactInfo from '../order-contacts-box/order-contact-info.interface';
import * as Orders from '../models';
@Component({
	selector: 'app-view-order',
	templateUrl: './view-order.component.html',
	styleUrls: ['./view-order.component.scss']
})
export class ViewOrderComponent implements OnInit {

	@Input() order: Orders.Order = null;
	@Input() otherSites: Orders.Order[] = [];
	@Output() selectOrder: EventEmitter<Orders.Order> = new EventEmitter();
	@Output() back: EventEmitter<any> = new EventEmitter();
	public loading: boolean = false;
	public datesInCenter: boolean = false;

	public generalInfo: OrderInfoDetail[];
	public basicServiceInfo: OrderInfoDetail[];
	public keyDates: OrderInfoDetail[];
	public circuitOrdersInfo: OrderInfoDetail[];
	public contactsInfo: OrderContactInfo[];

	constructor(
		private location: Location,
    private store: Store<fromStore.State>,
    private dialog: MatDialog,
	) { }

	ngOnInit(): void {
		this.updateUrl();
		this.getAllInfo();
		if (this.order.type == Orders.OrderType.VOICE || this.order.type == Orders.OrderType.SDWAN) {
			this.datesInCenter = true;
		}
	}

	private updateUrl() {
		this.location.replaceState('/orders-table/' + this.order.id + '/view');
	}

	private getAllInfo() {
		if(!this.order) return;
		this.generalInfo = this.order.getGeneralInformation();
		this.basicServiceInfo = this.order.getBasicServiceInfo();
		this.keyDates = this.order.getDates();
		this.circuitOrdersInfo = this.order.getCircuitOrderInfo();
		this.contactsInfo = this.order.getContactsInfo();
	}

	public goBack() {
		this.location.replaceState('/orders-table');
		this.back.emit();
	}

	public openOtherOrder(order: Orders.Order) {
		this.selectOrder.emit(order);
	}

	public async onCreateTicket() {
		const browserSize = window.innerWidth <= 700 ? 'small' : '';
		// open modal
		const dialogConfig = {
			autoFocus: true,
			minHeight: browserSize === 'small' ? '315px' : '380px',
			width: browserSize === 'small' ? 'calc(100vw - 20px)' : '650px',
			maxWidth: '650px',
			data: {
				browserSize: browserSize,
				originPage: 'Service'
			},
			disableClose: true
		};
		this.dialog.open(CreateTicketComponent, dialogConfig);
	}

}
