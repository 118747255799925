<div class="account-menu">
  <a (click)="toggle()" #mobileMenuButton>
    <div class="profile-icon" [ngClass]="{ active: toggleMenu }"></div>
  </a>
  <nav [@parentAnimation]="toggleMenu" #mobileMenuView>
    <nav [@modalStateDisplay]="toggleMenu" class="menu">
      <div class="container" #mobileMenuView>
        <ul>
          <li
            [@childAnimation]="toggleMenu"
            (click)="menuItemSelect()"
            *ngFor="let item of nav"
          >
            <ng-template [ngxPermissionsOnly]="item.permissions">
              <a
                routerLinkActive="active-link"
                [routerLinkActiveOptions]="{ exact: item.exact }"
                routerLink="{{ item.link }}"
                ><div>{{ item.name }}</div></a
              >
            </ng-template>
          </li>
          <!-- TODO: Add monitoring to mobile menu -->

          <li
            [@childAnimation]="toggleMenu"
            (click)="menuItemSelect()"
            *ngFor="let item of nav3"
          >
            <ng-template [ngxPermissionsOnly]="item.permissions">
              <a
                routerLinkActive="active-link"
                [routerLinkActiveOptions]="{ exact: item.exact }"
                routerLink="{{ item.link }}"
                ><div>{{ item.name }}</div></a
              >
            </ng-template>
          </li>
          
          <li id="logout" [@childAnimation]="toggleMenu" (click)="logOut()">
            <div class="menu-item">Sign Out</div>
          </li>

        </ul>
      </div>
    </nav>
  </nav>
</div>
