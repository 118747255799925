import { SurveyRatingSince, SurveyYearNPS } from '../models/survey.model';
import { api } from '../models/api-url.model';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {
  constructor(private apiService: ApiService) {}

  getYearNPS(year: string | number): Observable<SurveyYearNPS> {
    return this.apiService.get(`${api.surveyURL}/year-nps/${year}`);
  }

  getRatingSince(since: moment.MomentInput): Observable<SurveyRatingSince> {
    const date = moment(since)
    return this.apiService.get(`${api.surveyURL}/rating-since/${date.format('YYYY-MM-DD')}`);
  }

}
