import { LineValuesModalComponent } from "./line-values-modal/line-values-modal.component";
import {
  Component,
  EventEmitter,
  Input,
  inject,
  Output,
  ViewChild,
  effect,
  ElementRef,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ChartService } from "./services/chart.service";
import { BaseChartDirective } from "ng2-charts";
import { HorizontalBarsKpi } from "../../types";
import * as domtoimage from "dom-to-image-more";
import * as XLSX from "xlsx";

@Component({
  selector: "app-line-values",
  templateUrl: "./line-values.component.html",
  styleUrls: ["./line-values.component.scss"],
  providers: [ChartService],
})
export class LineValuesComponent {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective;
  @Input({ required: true }) set data(v: HorizontalBarsKpi) {
    this.data$.set(v);
  }
  @Input() loading: boolean = true;
  @Input() total: number = 0;
  @Output() navigate = new EventEmitter<string>();
  @ViewChild("modalContent") modalContent: ElementRef | undefined;

  //#region     BEGIN ChartService
  public readonly chartService$ = inject(ChartService);
  public get data$() {
    return this.chartService$.data$;
  }
  public readonly updateChartEffect$ = effect(() => {
    this.chartService$.data$.set({
      value: [
        { label: "Jan", value: 10, link: "/jan" },
        { label: "Feb", value: 20, link: "/feb" },
        { label: "Mar", value: 30, link: "/mar" },
        { label: "Apr", value: 40, link: "/apr" },
      ],
      id: "",
      title: "",
    });
    this.chart?.update();
  });
  //#endregion  END ChartService

  private readonly matDialog = inject(MatDialog);
  public openModal(): void {
    this.matDialog.open<LineValuesModalComponent, HorizontalBarsKpi>(
      LineValuesModalComponent,
      {
        width: "calc(100vw - 1rem)",
        data: this.data$(),
        hasBackdrop: true,
      }
    );
  }

  public downloadImage(): void {
    const contentElement = this.modalContent?.nativeElement;

    if (contentElement) {
      const options = {
        width: contentElement.offsetWidth,
        height: contentElement.offsetHeight,
        scale: 1.5,
        style: {
          transform: "scale(1)",
          transformOrigin: "top center",
        },
      };

      domtoimage
        .toPng(contentElement, options)
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.href = dataUrl;
          link.download = "component-image.png";
          link.click();
        })
        .catch((error) => {
          console.error("Error", error);
        });
    }
  }

  public downloadXlsx(): void {
    const chartData = this.chartService$.lineChartData$();

    const data = [];
    const labels = chartData.labels || [];

    const headers = [
      "Label",
      ...chartData.datasets.map((dataset) => dataset.label),
    ];
    data.push(headers);

    labels.forEach((label, index) => {
      const row = [label];
      chartData.datasets.forEach((dataset) => {
        row.push(dataset.data[index] || 0);
      });
      data.push(row);
    });

    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, "Chart Data");

    XLSX.writeFile(wb, "chart-data.xlsx");
  }
}
