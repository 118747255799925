import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiService } from './api.service';
import { api } from '../models/api-url.model';
import { HttpParams } from '@angular/common/http';
import { expand, takeWhile, reduce, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SynapseService {
  constructor(private apiService: ApiService) { }

  getNitelIQ(): Observable<any[]> {
    return this.apiService.get(api.getNitelIQ);
  }

  getUnusualMetrics(params: any): Observable<any[]> {
    let httpParams = new HttpParams();
    Object.keys(params).forEach(key => {
      httpParams = httpParams.set(key, params[key]);
    });
    return this.apiService.get(api.getUnusualMetrics, { params: httpParams });
  }

  // Method to loop the calls until an empty array or error
  fetchAllUnusualMetrics(time: string, showMockData: boolean, accountName: string): Observable<any[]> {

    const params = {
      "accountName": accountName,
      "time": time,
      "showMockData": showMockData,
      "page": 1 // Start with page 1
    };

    return this.getUnusualMetrics(params).pipe(
      expand(records => {
        if (!records || records.length === 0) {
          // Stop expanding if an empty array is returned
          return of([]);
        }
        params.page++; // Increment the page for the next call
        return this.getUnusualMetrics(params);
      }),
      takeWhile(records => records?.length > 0, true), // Include the last response even if it's empty
      reduce((allRecords, currentRecords) => {  // Accumulate all records
        if (currentRecords?.length) {
          return allRecords.concat(currentRecords)
        }
        return allRecords;
      }, []),
      catchError(error => {
        console.error('Error fetching metrics:', error);
        return of([]); // Return an empty array on error
      })
    );
  }

  getMetricDetails(params: any): Observable<any[]> {
    let httpParams = new HttpParams();
    Object.keys(params).forEach(key => {
      httpParams = httpParams.set(key, params[key]);
    });
    return this.apiService.get(api.getMetricDetails, {params: httpParams});
  }

  fetchAllMetricDetails(accountName: string, time: string, dimension: string, showMockData: boolean): Observable<any[]> {
    const params = {
      "accountName": accountName,
      "time": time, 
      "dimension": dimension,
      "showMockData": showMockData,
      "page": 1
    };

    return this.getMetricDetails(params).pipe(
      expand(records => {
        if (!records || records.length === 0) {
          // Stop expanding if an empty array is returned
          return of([]);
        }
        params.page++; // Increment the page for the next call
        return this.getMetricDetails(params);
      }),
      takeWhile(records => records?.length > 0, true), // Include the last response even if it's empty
      reduce((allRecords, currentRecords) => {  // Accumulate all records
        if (currentRecords?.length) {
          return allRecords.concat(currentRecords)
        }
        return allRecords;
      }, []),
      catchError(error => {
        console.error('Error fetching metric details:', error);
        return of([]); // Return an empty array on error
      })
    );
  }

  getAggregatedMetrics(accountName: string, time: string, dimension: string, metric: string, sortBy: string, showMockData: boolean): Observable<any[]> {
    let params = {
      "accountName": accountName,
      "time": time, 
      "dimension": dimension,
      "metric": metric || metric,
      "showMockData" : showMockData,
      "orderBy": sortBy
    };
    let httpParams = new HttpParams();
    Object.keys(params).forEach(key => {
      httpParams = httpParams.set(key, params[key]);
    });
    return this.apiService.get(api.getAggregatedMetrics, {params: httpParams});
    // return this.apiService.get(api.getAggregatedMetrics, [{accountName: "M&K Truck Center", time: time, dimension: dimension, metric: metric, sortBy: sortBy}]);
  }

}