import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import {
  Router,
  RouterEvent,
  Event,
  NavigationStart,
  NavigationEnd,
  NavigationError,
  NavigationCancel,
} from "@angular/router";
import { DEFAULT_INTERRUPTSOURCES, Idle } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import { Store } from "@ngrx/store";
import { Observable, Subscription } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { NgxPermissionsService } from "ngx-permissions";

import * as fromStore from "src/app/store/";
import { AuthService } from "./services/auth.service";
import { environment } from "src/environments/environment";
import { SessionExpiredComponent } from "./shared/components";
import { StateService } from "./services/state.service";
import { GoogleTagManagerService } from "angular-google-tag-manager";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  title = "MyNitel";
  loading = true;
  permissions = [];

  // How long a user can be inactive before considered idle, in seconds.
  sessionLengthInSeconds = 900; // 15 minutes.
  // How long a user can be idle before considered timed out, in seconds.
  timeoutWarningSeconds = 60; // 1 minute.
  timedOut = false;
  lastPing: Date = null;

  browserSubscription: Subscription;
  routerSubscription: Subscription;
  browserSize$: Observable<string>;
  browserSize: string;

  displayLoadingSpinner: boolean = false;

  constructor(
    private authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private idle: Idle,
    private keepAlive: Keepalive,
    private permissionsService: NgxPermissionsService,
    private router: Router,
    private store: Store<fromStore.State>,
    private state: StateService,
    private gtmService: GoogleTagManagerService
  ) {
    this.browserSize$ = this.store.select(fromStore.getBrowserSizeName);
    this.browserSubscription = this.browserSize$.subscribe((name) => {
      if (name) {
        this.browserSize = name;
      }
    });

    this.routerSubscription = this.router.events.subscribe((event: Event) => {
      this.checkRouterEvent(event);
      // google analytics
      if (
        (environment.production || environment.dev) &&
        event instanceof NavigationEnd
      ) {
        const gtmTag = { page_path: event.urlAfterRedirects };
        this.gtmService.pushTag(gtmTag);
      }
    });

    this.initSessionTimeouts();

    this.state.getState().subscribe((st) => {
      if (st == "hide_loading_overlay") {
        this.toggleLoadingOverlay(false);
      }
      if (st == "show_loading_overlay") {
        this.toggleLoadingOverlay(true);
      }
    });
  }

  async ngOnInit() {
    if (this.authService.apiAccount) {
      this.permissions = this.authService.apiAccount.user.Permissions;
      this.permissionsService.loadPermissions(this.permissions);
    }

    if (this.authService.isLoggedIn) {
      this.authService.trackUser(this.authService.apiAccount.user);
      this.idle.watch();
    }
  }

  ngOnDestroy() {
    this.browserSubscription.unsubscribe();
    this.routerSubscription.unsubscribe();
  }

  initSessionTimeouts() {
    // Set idle and timeout limits.
    // See if there is an expires entry in local storage
    const expires = localStorage.getItem("ng2Idle.main.expiry");
    // console.log(`ng2Idle.main.expiry = ${expires}.`);
    if (expires) {
      const timestamp = Number(expires);
      const expireDate = new Date(timestamp);
      const remainingSession = Math.floor(
        (expireDate.getTime() - Date.now()) / 1000
      );

      // console.log(`Local storage session expiration = ${expireDate}`);
      // console.log(`Remaining seconds = ${remainingSession}.`);

      // If the user has time left in their session from a previous visit or another tab
      // Set the timeout from localstorage
      if (remainingSession > 0) {
        this.idle.setIdle(remainingSession);
      } else {
        this.idle.setIdle(this.sessionLengthInSeconds);
      }
    } else {
      this.idle.setIdle(this.sessionLengthInSeconds);
    }

    // console.log(`Session Expires ${expires}.`);
    // console.log(`Start: idle is now ${this.idle.getIdle()}.`);

    this.idle.setTimeout(this.timeoutWarningSeconds);

    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    // No longer idle
    this.idle.onIdleEnd.subscribe(() => {
      // console.log(`User is no longer idle at ${Date.now()}`);
      this.resetSession();
    });

    // Timed out
    this.idle.onTimeout.subscribe(() => {
      this.timedOut = true;
      // console.log(`Session Timed out at ${Date.now()}`);

      this.dialog.closeAll();
      this.authService.logout("expired");
    });

    // User is idle
    this.idle.onIdleStart.subscribe(() => {
      // console.log(`User is idle at ${Date.now()}`);
      // console.log(`Idle = ${this.idle.getIdle()}`);

      this.idle.clearInterrupts();
      this.openDialog();
    });

    // dialog countdown start
    this.idle.onTimeoutWarning.subscribe((/* countdown */) => {
      // console.log(countdown);
    });

    // sets the ping interval
    this.keepAlive.interval(this.sessionLengthInSeconds);
    this.keepAlive.onPing.subscribe(() => {
      this.lastPing = new Date();
      return true;
    });
  }

  checkRouterEvent(routerEvent: Event | RouterEvent): void {
    if (routerEvent instanceof NavigationStart) {
      this.loading = true;
    }

    if (
      routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError
    ) {
      this.loading = false;
    }
  }

  resetSession() {
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    // console.log(`Idle = ${this.idle.getIdle()}`);
    this.idle.watch();
    this.timedOut = false;
  }

  openDialog() {
    // Dialog for session timeout
    const dialogConfig = {
      autoFocus: true,
      minHeight: "315px",
      width: this.browserSize === "small" ? "calc(100vw - 20px)" : "650px",
      maxWidth: "650px",
      data: {
        browserSize: this.browserSize,
      },
      disableClose: true,
    };

    const sessionExpiredDialog = this.dialog.open(
      SessionExpiredComponent,
      dialogConfig
    );
    sessionExpiredDialog.afterClosed().subscribe((staySignedIn) => {
      if (staySignedIn) {
        this.resetSession();
        this.changeDetectorRef.detectChanges();
      } else {
        this.authService.logout("expired");
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  toggleLoadingOverlay(show: boolean) {
    this.displayLoadingSpinner = show;
    this.changeDetectorRef.detectChanges();
  }
}
