import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { SynapseService } from 'src/app/services/synapse.service';
import { MatTableDataSource } from '@angular/material/table';

import { Store } from '@ngrx/store';
import * as fromStore from 'src/app/store/';
import { Subscription } from 'rxjs';

interface NetworkMetrics {
  device_name: string;
  source: string;
  time_frame: string;
  latency: number;
  jitter: number;
  packet_loss: number;
  data_usage: number;
  data_utilization: number;
  link_name: string;
  application: string;
}

@Component({
    selector: 'metric-table-component',
    styleUrls: ['./metric-table.component.scss'],
    templateUrl: './metric-table.component.html'
  })
  export class MetricTableComponent implements OnInit, OnDestroy{
    private metricSubscription: Subscription;
    private aggregatedMetricsSubscription: Subscription;

    metricType: string = '';

    mapOfMetricNames: Map<string, string> = new Map<string, string>([
      ['data_usage','Data Usage (MB)'],
      ['data_utilization','Data Utilization (MB)'],
      ['jitter','Jitter'],
      ['latency','Latency (ms)'],
      ['packet_loss','Packet Loss (%)']
    ]);

    selectedDimension: string = '';
    activationTime: string = '';
    selectedSorting: string = '';
    selectedMetricLabel: string = ''; 
    selectedDevice: string = '';
    selectedDeviceIndex: number = 0;
    selectedDeviceChartLabel: string = '';

    data = []; // Replace with your actual table data
    filteredData = this.data; // To store filtered data

    accountName: string = '';

    nitelIQData: any[] = [];
    nitelIQDataAggregated: any;

    // Time of Day Breakbown values
    public timeOfDayBreakdownData = [];

    // Top Metric data values
    public sortedMetricsData = [];
    public metricCategories = [];

    // Historical chart data
    public historicalMetricData = [];
    public historicalCategories = [];

    loading: boolean = false; // Loading state
    tableLoading: boolean = false;
    isNoContent: boolean = false; // Display flag for content availability
    showMockData = false; // Toggle between real and mock data
    isNoTableContent: boolean = false;

    // Table values
    public unmTableData: MatTableDataSource<NetworkMetrics>;    

    constructor(private synapseService: SynapseService, private cdr: ChangeDetectorRef, private store: Store<fromStore.State>) {
      this.selectedDimension = "device";
      this.activationTime = "6h";
      this.selectedSorting = "DESC";
      this.metricType ='data_usage';
    }

    ngOnInit(): void {
      if (this.accountName) {
        this.loadTableData(); // Refresh data
        this.loadAggregatedData();
      }
      else {
        this.store.select(fromStore.getCurrentUser).subscribe({
          next: (data) => {
            this.accountName = data.Account?.Name;
            this.loadTableData(); // Refresh data
            this.loadAggregatedData();
          },
          error: (err) => console.error('Error fetching user data:', err)
        });
      }
    }
    
    getMetricLableByName():void {
      this.selectedMetricLabel = this.mapOfMetricNames.get(this.metricType);
    }

    // Method to handle the emitted value
    onChildSelectionChange(value: string): void {
      if (this.activationTime != value) {
        this.activationTime = value;
        this.loadTableData();
        this.loadAggregatedData();
      }
    }

    loadTableData(): void {
      this.unmTableData = new MatTableDataSource<NetworkMetrics>();
      this.tableLoading = true; // Start spinner
      this.isNoTableContent = false;
      this.metricSubscription = this.synapseService.fetchAllMetricDetails(this.accountName, this.activationTime, this.selectedDimension, this.showMockData).subscribe({
        next: (data) => {
          this.nitelIQData = data; // Store fetched data
          if (this.nitelIQData?.length) {
            this.getUnmTableData(); // Update metrics and charts
            this.isNoTableContent = false; // Data is available
            this.cdr.detectChanges();
          } else {
            this.isNoTableContent = true; // No data
            console.error("Empty getMetricDetails: ", this.nitelIQData);
          }
          this.tableLoading = false; // Stop spinner
        },error: (err) => {
          this.isNoTableContent = true;
          this.tableLoading = false;
          console.error('Error fetching metrics:', err); // Log errors
        } 
      });
    }

    // Toggles between mock and real data
  toggleMockData(event: boolean): void {
    this.showMockData = event; // Toggle flag
    this.ngOnInit(); // Reload data
  }

    loadAggregatedData(): void {
      this.loading = true; // Start spinner
      this.aggregatedMetricsSubscription = this.synapseService.getAggregatedMetrics(this.accountName, this.activationTime, this.selectedDimension, this.metricType, this.selectedSorting, this.showMockData).subscribe(
        (data) => {
          this.nitelIQDataAggregated = data;
          if (this.nitelIQDataAggregated) {
            this.getMetricLableByName();
            this.getMetricsData(); // Update metrics and charts
            this.isNoContent = false;
            this.loading= false;
            this.cdr.detectChanges();
          } else {
            console.error("Empty getAggregatedMetrics: ", this.nitelIQDataAggregated?.metric_ranks.length);
            this.isNoContent = true; // No data
          }
          this.cdr.detectChanges();
          this.loading = false; // Stop spinner
        },
        (error) => {
          this.nitelIQDataAggregated = [];
          this.sortedMetricsData = [];
          this.metricCategories = [];
          this.historicalMetricData = [];
          this.historicalCategories = [];
          this.loading = false; // Stop spinner
          this.isNoContent = true;
          this.cdr.detectChanges();
          console.error('Error fetching aggregated metrics:', error);
        } // Log errors
      );
    }

    onMetricChange() {
      this.getMetricLableByName();
      this.loadAggregatedData();
    }

    onSortingChange(value: string) {
      if (this.selectedSorting !== value) {
        this.selectedSorting = value;
        this.loadAggregatedData();
        this.loadTableData();
      }
    }

    onDimensionChange(value: string) {

      if (this.selectedDimension !== value) {
        this.selectedDimension = value;
        if (this.selectedDimension !== 'connection') {
          this.metricType = 'data_usage';
        }
        this.loadAggregatedData();
        this.loadTableData();
      }
    }

    getTimeOfDayBreakdownData(): void {

      let firstTimeFrame = 0;
      let secondTimeFrame = 0;
      let thirdTimeFrame = 0;
  
      for (let key in this.nitelIQData) {
        let currentItem = this.nitelIQData[key];
        let time_frame = currentItem.time_frame;
  
        let date = new Date(time_frame);
        let hours = date.getUTCHours();
  
        if (hours >= 0 && hours < 8) {
          firstTimeFrame++;
        } else if (hours >= 8 && hours < 16) {
          secondTimeFrame++;
        } else if (hours >= 16 && hours <= 23) {
          thirdTimeFrame++;
        }
      }
      this.timeOfDayBreakdownData = [firstTimeFrame, secondTimeFrame, thirdTimeFrame];
  
    }

    getMetricsData(): void {

      let deviceNameMap = new Map();
      let timeValuesMap = new Map();

      let containsItem = this.nitelIQDataAggregated?.metric_ranks.filter(device => this.selectedDevice === device.deviceName);
  
      for (let key in this.nitelIQDataAggregated?.metric_ranks) {
        let currentItem = this.nitelIQDataAggregated?.metric_ranks[key];
        
        deviceNameMap.set(currentItem.dimensionName, currentItem.metric_value);

        for (let index in currentItem.trend) {
          let historicalMetric = timeValuesMap.get(currentItem.dimensionName) || []; 
          historicalMetric.push({time_frame: this.formatDate(currentItem.trend[index].date), metric_value: currentItem.trend[index].value});
          timeValuesMap.set(currentItem.dimensionName, historicalMetric);
        }
      }    
      
      let dataForChart = [];
      let categoriesForChart = [];
  
      for (let [key, value] of deviceNameMap) {
        if (dataForChart.length < 10) {
          dataForChart.push(Number(value));
          categoriesForChart.push(key);
        }
      }

      if (this.selectedSorting == 'ASC') {
          dataForChart.reverse();
          categoriesForChart.reverse();
      }
  
      this.sortedMetricsData = [
        {
          name: this.selectedMetricLabel,
          data: dataForChart
        }
      ];

      if (!this.selectedDevice || !containsItem.length) {
        this.selectedDevice = categoriesForChart[0];
        this.selectedDeviceIndex = 0;
      }
  
      this.metricCategories = categoriesForChart;
      this.getHistoricalData();  
    }

    getHistoricalData(): void {

      for (let key in this.nitelIQDataAggregated?.metric_ranks) {
        
        let currentItem = this.nitelIQDataAggregated?.metric_ranks[key];

        if (currentItem.dimensionName === this.selectedDevice) {
          let historicalData = [];
          let historicalCategories = [];
          for (let index in currentItem.trend) {
            historicalData.push(currentItem.trend[index].value);
            let dateValue = new Date(currentItem.trend[index].date);
            dateValue.setHours(dateValue.getHours() - 2*(dateValue.getTimezoneOffset()/60));
            historicalCategories.push([dateValue.getTime(), currentItem.trend[index].value]);
          }
          
          this.historicalMetricData = [
            {
              name: this.selectedMetricLabel,
              data: historicalData
            }
          ];
          this.historicalCategories = historicalCategories;
          let namePrefix = (this.selectedDimension === 'connection') ? this.mapOfMetricNames.get(this.metricType) : this.mapOfMetricNames.get('data_usage');
          this.selectedDeviceChartLabel = namePrefix + ' for ' + this.selectedDevice;
        }
      }   
    }

    getUnmTableData(): void {

      let networkMetrics: any[] = [];
  
      for (let key in this.nitelIQData) {
        let currentItem = this.nitelIQData[key];
        let metrics = currentItem.metrics;
  
        let tableItem = {
          account: currentItem.account,
          account_id: currentItem.account_id,
          carrier: currentItem.carrier,
          source: currentItem.source,
          device_id: currentItem.device_id,
          device_name: currentItem.device_name,
          time_frame: this.formatDate(currentItem.time_frame),
          bucket: currentItem.bucket,
          application: currentItem.application,
          link_name: currentItem.device_name + '-' + currentItem.connection
        };
  
        metrics.forEach(metric => { tableItem[metric.metric_type] = metric.metric_value; });
  
        networkMetrics.push(tableItem);
      }
  
      this.unmTableData = new MatTableDataSource(networkMetrics);  // Set data source
    }

    private formatDate(dateString: string): string {
      const date = new Date(dateString);
      date.setHours(date.getHours() - new Date().getTimezoneOffset()/60);
    
      // Format date as MM/DD/YYYY
      const formattedDate = date.toLocaleDateString('en-US', {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric'
      });
    
      // Format time as H:MM AM/PM
      const formattedTime = date.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
        second: 'numeric'
      });
    
      return `${formattedDate} ${formattedTime}`;
    }

    onChangeDevice(event: any) {
      // this.selectedDeviceIndex = event;
      this.selectedDevice = event;
      this.getHistoricalData();
    }

    ngOnDestroy() {
      if (this.metricSubscription) {
        this.metricSubscription.unsubscribe();
      }
      if (this.aggregatedMetricsSubscription) {
        this.aggregatedMetricsSubscription.unsubscribe();
      }
    }
  }