import { NgModule } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot } from '@angular/router';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { RouterStateSerializer } from '@ngrx/router-store';

/* Components */
import * as fromServiceInventoryComponents from 'src/app/inventory/components';
import * as fromOrdersContainers from 'src/app/orders/containers';
import * as fromTicketContainers from 'src/app/tickets/containers';
import * as fromBillingContainers from 'src/app/billing/containers';
import * as fromServiceInventoryContainers from 'src/app/inventory/containers';
import * as fromGuards from 'src/app/store/guards';
import * as fromOrdersTableComponents from './orders-table';
import * as UnusualNetworkMetricsContainers from 'src/app/unusual-network-metrics/containers';
import { AppLayoutComponent } from './_layout/app-layout/app-layout.component';
import { PublicLayoutComponent } from './_layout/public-layout/public-layout.component';
import { LoginComponent } from './core/login/login.component';
import { ResetPasswordComponent } from './core/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './core/forgot-password/forgot-password.component';
import { DashboardComponent } from './dashboard/dashboard-page/dashboard.component';
import { SummaryComponent } from './summary/summary-page/summary-page.component';
import { OperationsComponent } from './operations-dashboard/operations-dashboard/operations-page.component';
import { ImpersonationComponent } from './core/impersonate/impersonation.component';
import { NocPolicyComponent } from './noc-policy/noc-policy.component';

import { CustomSerializer } from './store/reducers/router.reducer';
import { MetricTableComponent } from './metric-details/containers';

const ROUTES: Routes = [
  {
    // Portal routes goes here here
    path: '',
    component: AppLayoutComponent,
    children: [
      {
        path: 'unusual-network-metrics',
        canActivate: [
          fromGuards.AuthGuard,
          fromGuards.UsersGuard,
          fromGuards.InvoiceAttachmentsGuard,
          fromGuards.PaymentsGuard,
          NgxPermissionsGuard
        ],
        data: {
          permissions: {
            only: ['Nitel IQ Participant']
          }
        },
        children: [{ path: '', component: UnusualNetworkMetricsContainers.UnmPageComponent }]
      },
      {
        path: 'billing',
        canActivate: [
          fromGuards.AuthGuard,
          fromGuards.InvoiceAttachmentsGuard,
          fromGuards.PaymentsGuard,
          NgxPermissionsGuard
        ],
        data: {
          permissions: {
            only: ['Invoice and Billing: Read']
          }
        },
        children: [{ path: '', component: fromBillingContainers.BillingPageComponent }]
      },
      {
        path: 'services/:serviceId',
        component: fromServiceInventoryComponents.InventoryDetailComponent,
        canActivate: [fromGuards.AuthGuard, fromGuards.ServicesGuard, NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['Services']
          }
        }
      },
      {
        path: 'services',
        canActivate: [fromGuards.AuthGuard, NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['Services']
          }
        },
        children: [{ path: '', component: fromServiceInventoryContainers.InventoryPageComponent }]
      },
      {
        path: 'orders/:orderId/line-items/:lineItemId',
        component: fromOrdersContainers.OrdersDetailShellComponent,
        canActivate: [fromGuards.OrdersGuard, fromGuards.AuthGuard, NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['Orders']
          }
        }
      },
      {
        path: 'orders/:orderId',
        component: fromOrdersContainers.OrdersDetailShellComponent,
        canActivate: [fromGuards.AuthGuard, fromGuards.OrdersGuard, fromGuards.SOFGuard, NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['Orders']
          }
        }
      },
      {
        path: 'orders',
        component: fromOrdersContainers.OrdersShellComponent,
        canActivate: [fromGuards.AuthGuard, NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['Orders']
          }
        }
      },
      {
        path: 'orders-table',
        component: fromOrdersTableComponents.OrdersIndex,
        canActivate: [fromGuards.AuthGuard, NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['Orders']
          }
        }
      },
      {
        path: 'orders-table/:orderId/view',
        component: fromOrdersTableComponents.OrdersIndex,
        canActivate: [fromGuards.AuthGuard, NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['Orders']
          }
        }
      },
      {
        path: 'tickets/:ticketId/comments',
        component: fromTicketContainers.TicketCommentComponent,
        canActivate: [fromGuards.AuthGuard, fromGuards.TicketsGuard, NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['Ticketing: Read']
          }
        }
      },
      {
        path: 'tickets/:ticketId',
        component: fromTicketContainers.TicketsDetailShellComponent,
        canActivate: [fromGuards.AuthGuard, fromGuards.TicketsGuard, NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['Ticketing: Read']
          }
        }
      },
      {
        path: 'tickets',
        component: fromTicketContainers.TicketsShellComponent,
        canActivate: [fromGuards.AuthGuard, NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['Ticketing: Read']
          }
        }
      },
      {
        path: 'summary',
        redirectTo: 'orders-dashboard',
        pathMatch: 'full'
      },
      {
        path: 'orders-dashboard',
        component: SummaryComponent,
        canActivate: [fromGuards.AuthGuard, fromGuards.UsersGuard, NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['Orders']
          }
        }
      },
      {
        path: 'operations-summary',
        component: OperationsComponent,
        canActivate: [fromGuards.AuthGuard, fromGuards.UsersGuard, NgxPermissionsGuard],
        data: {
          permissions: {
            except: []
          }
        }
      },
      {
        path: '',
        component: DashboardComponent,
        canActivate: [fromGuards.AuthGuard, fromGuards.UsersGuard, NgxPermissionsGuard],
        data: {
          permissions: {
            except: []
          }
        }
      },
      {
        path: 'noc-policy',
        component: NocPolicyComponent,
        canActivate: [fromGuards.AuthGuard, fromGuards.UsersGuard, NgxPermissionsGuard],
        data: {
          permissions: {
            except: []
          }
        }
      },
      {
        path: 'metric-details',
        component: MetricTableComponent,
        canActivate: [fromGuards.AuthGuard, fromGuards.UsersGuard, NgxPermissionsGuard],
        data: {
          permissions: {
            except: []
          }
        }
      }
    ]
  },
  {
    // Logged out routes go here
    path: '',
    component: PublicLayoutComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'forgot-password', component: ForgotPasswordComponent },
      { path: 'reset-password/:token', component: ResetPasswordComponent },
      { path: 'signin_as/:token', component: ImpersonationComponent },
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      { path: '**', redirectTo: 'login', pathMatch: 'full' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(ROUTES, {})],
  exports: [RouterModule],
  providers: [
    { provide: RouterStateSerializer, useClass: CustomSerializer },
    {
      provide: 'externalUrlRedirectResolver',
      useValue: (route: ActivatedRouteSnapshot) => {
        window.location.href = (route.data as any).externalUrl;
      }
    }
  ]
})
export class AppRoutingModule {}
