<div class="gauge-value-item stats">
  <div class="gauge-value-item__header">
    <h1 class="gauge-value-item__title">{{ data.title }}</h1>
    <!-- <div>
      <button
        mat-icon-button
        class="gauge-value-item__expand"
        (click)="downloadXlsx()"
      >
        <mat-icon>file_open</mat-icon>
      </button>
      <button
        mat-icon-button
        class="gauge-value-item__expand"
        (click)="openModal()"
      >
        <mat-icon>fullscreen</mat-icon>
      </button>
    </div> -->
  </div>
  <div
    class="gauge-value-item__body"
    [appTooltip]="tooltipTemplate"
    #modalContent
  >
    <ngx-gauge
      [value]="data.value"
      [min]="data.minValue"
      [max]="data.maxValue"
      [type]="'arch'"
      [cap]="'butt'"
      [thick]="12"
      [margin]="20"
      [thresholds]="data.thresholds"
      [label]="data.numberLabel"
      [markers]="data.markers"
    />
  </div>
  <!-- <div class="gauge-value-item__footer">
    <a [routerLink]="data.hyperlink">
      {{ data.hyperlinkText || "View " + data.title }}
    </a>
  </div> -->
</div>

<ng-template #tooltipTemplate>
  <div>
    <strong>{{ data.value }}</strong>
  </div>
</ng-template>
