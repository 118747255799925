import { Component, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable, timer } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'app-session-expired',
  templateUrl: './session-expired.component.html',
  styleUrls: ['./session-expired.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionExpiredComponent {
  counter$: Observable<number>;
  count = 61;

  constructor(public dialogRef: MatDialogRef<SessionExpiredComponent>) {
    this.counter$ = timer(0, 1000).pipe(
      take(this.count),
      // eslint-disable-next-line no-plusplus
      map(() => --this.count)
    );
  }

  closeDialog(staySignedIn: boolean) {
    this.dialogRef.close(staySignedIn);
  }
}
