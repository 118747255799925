import { Component, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatGridList } from '@angular/material/grid-list';
import { MatTableDataSource } from '@angular/material/table';
import { SynapseService } from 'src/app/services/synapse.service';
import { Subscription } from "rxjs";
import { Store } from '@ngrx/store';
import * as fromStore from 'src/app/store/';

// Define the structure for network metrics
interface UnusualNetworkMetrics {
  edgeName: string;
  interfaceName: string;
  timestamp: string;
  bandwidth: number;
  latency: number;
  jitter: number;
  packetloss: number;
  bucket: string;
  datausage: number;
  anomalies: string;
}

@Component({
  selector: 'app-unm-page',
  templateUrl: './unm-page.component.html',
  styleUrls: ['./unm-page.component.scss'],
})
export class UnmPageComponent implements OnDestroy {
  @ViewChild(MatGridList, { static: true }) matGridList!: MatGridList; // Access the Material Grid List

  // State variables
  measurementCategory: string = 'warning'; // Default category
  dataUtilization = '0'; // Placeholder for data utilization metric
  latency = '0'; // Placeholder for latency metric
  jitter = '0'; // Placeholder for jitter metric
  packetLost = '0'; // Placeholder for packet loss metric

  accountName: string = ''; // Account Info
  nitelIQData: any[] = []; // Store fetched data
  selectedTime: string = ''; // Selected time filter
  showMockData = false; // Toggle between real and mock data
  isNoContent = false; // Display flag for content availability
  loading: boolean = false; // Loading state

  // Data for severity breakdown chart
  severityBreakdownData: number[] = []; // Chart data for different severities
  severityBreakdownLabels: string[] = ['Warning', 'Critical', 'Other']; // Labels for severity categories
  severityBreakdownTitle: string = 'Severity Breakdown'; // Title of the severity breakdown chart

  // Data for time-of-day breakdown chart
  timeOfDayBreakdownData: number[] = []; // Chart data for time-of-day distribution
  timeOfDayBreakdownLabels: string[] = ['12a - 8a', '8a - 4p', '4p - 12a']; // Labels for time-of-day buckets
  timeOfDayBreakdownTitle: string = 'Time of Day Breakdown'; // Title of the time-of-day chart

  // Data for the top unusual devices chart
  topUnusualDevicesData: { name: string; data: number[] }[] = []; // Chart data for top devices
  topUnusualDevicesCategories: string[] = []; // Categories (device names) for the top devices chart

  // Table data source
  unmTableData = new MatTableDataSource<UnusualNetworkMetrics>();

  // Subscriptions
  userSubscription: Subscription;
  synapseSubscription: Subscription;

  constructor(
    private synapseService: SynapseService,
    private cdr: ChangeDetectorRef,
    private store: Store<fromStore.State>
  ) { }

  // Cleanup logic to unsubscribe from observables when the component is destroyed
  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.synapseSubscription) {
      this.synapseSubscription.unsubscribe();
    }
  }

  // Handle time selection change from a child component
  onChildSelectionChange(value: string): void {
    this.selectedTime = value; // Update selected time
    this.getUserInfoAndLoadData();
  }

  // Toggles between mock and real data
  toggleMockData(event: boolean): void {
    this.showMockData = event; // Toggle flag
    this.getUserInfoAndLoadData(); // Reload data
  }

  // Retrieves user information and loads unusual network data
  getUserInfoAndLoadData(): void {
    if (this.accountName) {
      this.loadNitelIQData(); // Refresh data
    }
    else {
      this.userSubscription = this.store.select(fromStore.getCurrentUser).subscribe({
        next: (data) => {
          this.accountName = data.Account?.Name;
          this.loadNitelIQData(); // Refresh data
        },
        error: (err) => console.error('Error fetching user data:', err)
      });
    }
  }

  // Fetch data from the service
  loadNitelIQData(): void {

    // Reset table data and UI states
    this.unmTableData = new MatTableDataSource<UnusualNetworkMetrics>();
    this.loading = true; // Start spinner
    this.isNoContent = false;
    this.measurementCategory = 'warning';

    // Fetch data from the service
    this.synapseSubscription = this.synapseService.fetchAllUnusualMetrics(this.selectedTime, this.showMockData, this.accountName).subscribe({
      next: (data) => {
        this.nitelIQData = data; // Store fetched data
        if (this.nitelIQData?.length) {
          this.updateMetrics(); // Update metrics and charts
          this.isNoContent = false; // Data is available
        } else {
          this.isNoContent = true; // No data
        }
        this.loading = false; // Stop spinner
        this.cdr.detectChanges(); // Trigger change detection manually
      },
      error: (err) => {// Log errors
        console.error('Error fetching metrics:', err);
        this.isNoContent = true;
        this.loading = false;
        this.cdr.detectChanges(); // Trigger change detection manually
      }
    });
  }

  // Update metrics, charts, and table data
  private updateMetrics(): void {
    this.calculateCategoryMetrics(this.measurementCategory); // Calculate metrics based on category
    this.calculateSeverityBreakdown(); // Update severity chart
    this.calculateTimeOfDayBreakdown(); // Update time-of-day chart
    this.calculateTopUnusualDevices(); // Update unusual devices chart
    this.populateUnmTableData(); // Update table data
  }

  // Calculate metrics (e.g., utilization, latency) based on the selected category
  calculateCategoryMetrics(category: string): void {
    let dataUtilization = 0,
      latency = 0,
      jitter = 0,
      packetLost = 0,
      countItems = 0;

    this.nitelIQData.forEach((item) => {
      const metrics = item.metrics;
      const bucket = item.bucket.toLowerCase().trim();

      // Filter data by category
      if (
        category === 'warning' || category === 'critical' ? 
        bucket === category : 
        bucket !== 'warning' && bucket !== 'critical'
      ) {
        metrics.forEach((metric: any) => {
          const value = parseFloat(metric.metric_value); // Parse metric value
          switch (metric.metric_type.toLowerCase().trim()) {
            case 'bandwidth':
              dataUtilization += value;
              break;
            case 'latency':
              latency += value;
              break;
            case 'jitter':
              jitter += value;
              break;
            case 'packetloss':
              packetLost += value;
              break;
          }
        });
        countItems++; // Increment filtered item count
      }
    });

    // Calculate averages and update state variables
    this.dataUtilization = (dataUtilization / countItems || 0).toFixed(2);
    this.latency = (latency / countItems || 0).toFixed(2);
    this.jitter = (jitter / countItems || 0).toFixed(2);
    this.packetLost = (packetLost / countItems || 0).toFixed(2);
    this.measurementCategory = category;
  }

  // Calculate the breakdown of severity (e.g., warning, critical, other)
  calculateSeverityBreakdown(): void {
    const buckets = { warning: 0, critical: 0, other: 0 }; // Initialize counters
    this.nitelIQData.forEach((item) => {
      const bucket = item.bucket.toLowerCase().trim(); // Normalize bucket name
      if (bucket === 'warning') buckets.warning++;
      else if (bucket === 'critical') buckets.critical++;
      else buckets.other++;
    });
    this.severityBreakdownData = [buckets.warning, buckets.critical, buckets.other]; // Update chart data
  }

  // Calculate time-of-day breakdown for metrics
  calculateTimeOfDayBreakdown(): void {
    const timeBuckets = [0, 0, 0]; // Initialize time buckets
    this.nitelIQData.forEach((item) => {
      const hours = new Date(item.time_frame).getUTCHours(); // Extract hours
      if (hours < 8) timeBuckets[0]++;
      else if (hours < 16) timeBuckets[1]++;
      else timeBuckets[2]++;
    });
    this.timeOfDayBreakdownData = timeBuckets; // Update chart data
  }

  // Calculate top unusual devices by occurrence
  calculateTopUnusualDevices(): void {
    const deviceMap = new Map<string, number>(); // Map for counting devices
    this.nitelIQData.forEach((item) => {
      const deviceName = item.device_name;
      deviceMap.set(deviceName, (deviceMap.get(deviceName) || 0) + 1); // Increment count
    });

    // Sort devices by count and take the top 5
    const sortedDevices = Array.from(deviceMap.entries())
      .sort((a, b) => b[1] - a[1])
      .slice(0, 5);

    // Prepare data for chart
    this.topUnusualDevicesData = [
      { name: 'Device Count', data: sortedDevices.map(([_, count]) => count) },
    ];
    this.topUnusualDevicesCategories = sortedDevices.map(([name]) => name);
  }

  // Populate table data with network metrics
  populateUnmTableData(): void {
    const tableData = this.nitelIQData.map((item) => {
      // Convert metrics array to key-value pairs
      const metrics = item.metrics.reduce(
        (acc: any, metric: any) => ({ ...acc, [metric.metric_type]: metric.metric_value }),
        {}
      );
      // Construct table row
      return {
        edgeName: item.device_name,
        interfaceName: item.source,
        timestamp: this.formatDate(item.time_frame), // Format timestamp
        bucket: item.bucket,
        ...metrics, // Spread metrics into row
      };
    });
    this.unmTableData.data = tableData; // Update table data source
  }

  // Format a timestamp into a readable string
  private formatDate(dateString: string): string {
    const date = new Date(dateString);
    return `${date.toLocaleDateString('en-US')} ${date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      second: '2-digit',
      hour12: true
    })}`;
  }
}