import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  OnInit,
  OnDestroy,
  ViewChild
} from '@angular/core';

import { OrderTableComponent } from '../table/order-table.component';
import { ActivatedRoute } from '@angular/router';
import OrderRow from '../table/order-row.interface';
import * as Orders from '../models';
import { Store } from '@ngrx/store';
import * as fromStore from 'src/app/store/';
import { BehaviorSubject, Observable, Subscription, map } from 'rxjs';
import { OrderState } from 'src/app/store/reducers/orders.reducer';
import { Order, OrderDetailViews as views } from 'src/app/models/order.model';
import { OrdersParserService } from '../../services/orders-parser.service';
import { OrderService } from '../../services/order.service';
import { StateService } from '../../services/state.service';
import { formatDate as formatDatePipe } from '@angular/common';

@Component({
  selector: 'app-table-page',
  templateUrl: './table-page.component.html',
  styleUrls: ['./table-page.component.scss']
})
export class TablePageComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
  @Input() orders: Orders.Order[];
  @Input() noOrders: boolean = false;
  @Output() selectOrder: EventEmitter<Orders.Order> = new EventEmitter();
  public loading: boolean = true;

  public filteredOrders: Orders.Order[];
  public data: OrderRow[];
  public lastUpdate: string = '...';

  public enumTypes: any[] = [];
  public enumStatus: any[] = [];
  public enumAddress: any[] = [];
  public selectedType: any[] = [];
  public selectedStatus: any[] = [];
  public selectedStatusValues: any = () =>
    this.selectedStatus
      .map((s) => s.value)
      .filter((val) => val >= 0)
      .sort();

  public selectedAddress: any[] = [];

  public showFilterBt: boolean = false;

  @ViewChild('orderstable') table: OrderTableComponent;


  constructor(
    private ref: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private orderStore: Store<OrderState>,
    private OrderService: OrderService,
    private Service: OrdersParserService,
    private State: StateService
  ) {}
  ngOnDestroy(): void {
    document.querySelector('body').classList.remove('orders-table-page');
  }

  ngOnInit() {
    this.getData();
    this.startFilters();
    document.querySelector('body').classList.add('orders-table-page');
  }

  ngOnChanges(changes) {
    let orderChanges = changes.orders;
    if (orderChanges.currentValue && orderChanges.currentValue.length > 0) {
      this.parseData(orderChanges.currentValue);
      this.startDynamicFilters();
      this.filterData();
    }
  }

  ngAfterViewInit() {
    this.checkUrlData();
    window.setTimeout(() => {
      this.State.sendState('hide_loading_overlay');
    }, 500);
  }

  private checkUrlData() {
    this.activatedRoute.queryParams.subscribe((params) => {
      let urlFilter: boolean = false;
      if (params['service']) {
        this.selectedType = params['service'].split(',');
        urlFilter = true;
      }
      if (params['status']) {
        this.selectStatusFilter(params['status'].split(',').map((s) => +s));
        this.organizeStatusFilters();
        urlFilter = true;
      }
      if (params['location']) {
        this.selectedAddress = params['location'].split('|');
        urlFilter = true;
      }
      if (urlFilter) this.filterData();
    });
  }
  public organizeStatusFilters(): void {
    let status = this.selectedStatus.map((s) => s.value).filter((val) => val >= 0);
    if (
      status.includes(Orders.Status.FOC_PENDING_WAITING_VENDOR) ||
      status.includes(Orders.Status.FOC_PENDING_SPECIAL_CONSTRUCTION) ||
      status.includes(Orders.Status.FOC_PENDING_CARRIER_BUILD) ||
      status.includes(Orders.Status.FOC_PENDING_CUST_REQ)
    ) {
      if (!status.includes(Orders.Status.FOC_PENDING)) {
        return this.selectStatusFilter([Orders.Status.FOC_PENDING]);
      }
    }
  }

  public sendOrder(o: Orders.Order): void {
    this.selectOrder.emit(o);
  }

  public removeFromFilter(rm: any): void {
    switch (rm.type) {
      case 'service':
        this.selectedType = this.selectedType.filter((i) => i.value != rm.item.value);
        break;
      case 'status':
        this.selectedStatus = this.selectedStatus.filter((i) => i.value != rm.item.value);
        this.hierarchyVerifications();
        break;
      case 'address':
        this.selectedAddress = this.selectedAddress.filter((i) => i != rm.item);
        break;
      default:
        break;
    }
    this.filterData();
  }
  public clearServices(): void {
    this.selectedType = [];
    this.filterData();
  }
  public clearAddress(): void {
    this.selectedAddress = [];
    this.filterData();
  }

  public filterData(): void {
    this.loading = true;
    this.filteredOrders = this.orders;
    if (this.selectedType.length > 0) {
      this.filteredOrders = this.filteredOrders.filter((o) => this.selectedType.includes(o.service_name));
    }
    if (this.selectedAddress.length > 0) {
      this.filteredOrders = this.filteredOrders.filter((o) => this.selectedAddress.includes(o.address));
    }
    this.filterDataStatus();
    this.parseData(this.filteredOrders);
  }

  public filterDataStatus(): void {
    let status = this.selectedStatusValues();
    if (status.length > 0) {
      this.filteredOrders = this.filteredOrders.filter((o) => status.includes(o.milestone));
    }
    if (status.indexOf(Orders.Status.FOC_PENDING) >= 0) {
      let pending = status.filter((st) => {
        return [
          Orders.Status.FOC_PENDING_WAITING_VENDOR,
          Orders.Status.FOC_PENDING_SPECIAL_CONSTRUCTION,
          Orders.Status.FOC_PENDING_CARRIER_BUILD,
          Orders.Status.FOC_PENDING_CUST_REQ
        ].includes(st);
      });
      if (pending.length > 0 && pending.length < 4) {
        this.filteredOrders = this.filteredOrders.filter((o) => {
          let intersection = o.foc_pending_reasons.filter((value) => pending.includes(value));
          return intersection.length > 0;
        });
      }
    }
  }

  // enum select functions:
  public onStatusChange(changes) {
    this.hierarchyVerifications();
    this.filterData();
  }

  public selectedStatusCount(): number {
    return this.selectedStatus.filter((s) => s.value >= 0).length;
  }

  public selectStatusFilter(st: any[], override?: boolean): void {
    const includingStatus = this.enumStatus.filter((s) => st.includes(s.value));
    if (override) this.selectedStatus = includingStatus;
    else this.selectedStatus = this.selectedStatus.concat(includingStatus);
  }
  private parentAddChild(parentId): void {
    const parent = this.enumStatus.filter((st) => st.value == parentId);
    const parentSelects = parent[0].selects;
    this.selectStatusFilter(parentSelects);
    this.hierarchyVerifications();
  }
  private parentRemoveChild(parentId): void {
    const parent = this.enumStatus.filter((st) => st.value == parentId);
    const parentSelects = parent[0].selects;
    this.selectedStatus = this.selectedStatus.filter((st) => !parentSelects.includes(st.value));
  }
  private toggleParent(parentId): void {
    let selected;
    if (this.selectedStatus.map((s) => s.value).indexOf(parentId) == -1) {
      this.parentAddChild(parentId);
      selected = true;
    } else {
      this.parentRemoveChild(parentId);
      selected = false;
    }
    this.hierarchyVerifications();
    this.filterData();
    return selected;
  }

  private unselectStatusFilter(st): void {
    this.selectedStatus = this.selectedStatus.filter((s) => s.value != st);
  }
  public hierarchyVerifications(): void {
    let parents = this.enumStatus.filter((s) => s.value < 0);
    parents.map((parent) => {
      const selects = parent.selects;
      if (!selects) return;
      const intersection = [...new Set(this.selectedStatusValues().filter((value) => selects.includes(value)))];
      if (intersection.length < selects.length) this.unselectStatusFilter(parent.value);
      else this.selectStatusFilter([parent.value]);
    });
  }

  private enumStatusFilterInit(): void {
    this.enumStatus = [
      {
        label: 'Show All Orders',
        value: -1,
        style: 'no-box',
        action: () => {
          this.selectedStatus = [];
          this.filterData();
        }
      },
      {
        label: 'Complete',
        name: 'Complete',
        value: Orders.Status.COMPLETE
      },
      {
        label: 'In Progress',
        name: 'In Progress',
        value: Orders.Status.IN_PROGRESS
      },
      {
        label: 'Pending',
        name: 'Pending',
        value: Orders.Status.PENDING
      },
      {
        label: 'Canceled',
        name: 'Canceled',
        value: Orders.Status.CANCELED
      },
      // {
      //   label: 'Circuits in-progress (excl. SD-WAN & Voice)',
      //   value: -3,
      //   selects: [
      //     Orders.Status.PREPARING,
      //     Orders.Status.FOC_PENDING,
      //     Orders.Status.FOC_ASSIGNED,
      //     Orders.Status.VENDOR_COMPLETION,
      //     Orders.Status.EQUIPMENT_SHIPPED,
      //     Orders.Status.ACTIVATION_PENDING,
      //     Orders.Status.ACTIVATION_SCHEDULED
      //   ],
      //   action: () => {
      //     this.toggleParent(-3);
      //   }
      // },
      // {
      //   label: '⇾ Equipment Shipped',
      //   name: 'Equipment Shipped',
      //   value: Orders.Status.EQUIPMENT_SHIPPED
      // },
      // {
      //   label: '⇾ FOC Date Assigned',
      //   name: 'FOC Date Assigned',
      //   value: Orders.Status.FOC_ASSIGNED
      // },
      // {
      //   label: 'In-progress Disconnects',
      //   name: 'In-progress Disconnects',
      //   value: Orders.Status.DISCONNECTED_IN_PROGRESS
      // },
      // {
      //   label: 'In-progress Move Requests',
      //   name: 'In-progress Move Requests',
      //   value: Orders.Status.MOVE_REQUESTED_IN_PROGRESS
      // },
      // {
      //   label: 'In Progress',
      //   name: 'In Progress',
      //   value: Orders.Status.IN_PROGRESS
      // },
      // {
      //   label: '⇾ Pending Activation',
      //   name: 'Pending Activation',
      //   value: Orders.Status.ACTIVATION_PENDING
      // },
      // {
      //   label: '⇾ Pending FOC Date',
      //   name: 'Pending FOC Date',
      //   value: Orders.Status.FOC_PENDING,
      //   action: () => {
      //     let st = this.selectedStatus.map((s) => s.value);
      //     if (!st.includes(Orders.Status.FOC_PENDING)) {
      //       st = st.filter(
      //         (stVal) =>
      //           ![
      //             Orders.Status.FOC_PENDING_WAITING_VENDOR,
      //             Orders.Status.FOC_PENDING_SPECIAL_CONSTRUCTION,
      //             Orders.Status.FOC_PENDING_CARRIER_BUILD,
      //             Orders.Status.FOC_PENDING_CUST_REQ
      //           ].includes(stVal)
      //       );
      //       this.selectStatusFilter(st, true);
      //     }
      //   }
      // },
      // {
      //   label: '⇾ Preparing Order',
      //   name: 'Preparing Order',
      //   value: Orders.Status.PREPARING
      // },
      // {
      //   label: '⇾ Scheduled Activation',
      //   name: 'Scheduled Activation',
      //   value: Orders.Status.ACTIVATION_SCHEDULED
      // },
      // {
      //   label: '⇾ Vendor Completion',
      //   name: 'Vendor Completion',
      //   value: Orders.Status.VENDOR_COMPLETION
      // }
    ];
  }

  private startFilters(): void {
    this.showFilterBt = window.innerWidth <= 700;
    this.enumStatusFilterInit();
  }
  private startDynamicFilters(): void {
    this.startServiceFilter();
    this.startAddressFilter();
  }
  private startServiceFilter(): void {
    this.enumTypes = [...new Set(this.data.map((item) => item.service))].sort();
    if (this.enumTypes.length <= 1) this.selectedType = [];
  }
  private startAddressFilter(): void {
    this.enumAddress = [...new Set(this.data.map((item) => item.address))].sort();
  }

  private parseData(ordArr): void {
    this.data = ordArr.reduce((acc: OrderRow[], order: Orders.Order) => {
      if (order?.id) {
        acc.push({
          id: order?.id,
          deal: order.deal,
          oli: order.oli,
          line_type: order.line_type,
          nit: order.nit,
          site: order.site,
          address: order.address,
          city: order.city,
          state: order.state,
          service: order.service_name,
          status: order?.getStatus(true),
          foc_date: order.formatDate(order.foc_date),
          expanded: false,
          full_order: order
        });
      }
      return acc;
    }, []);
      // let row: OrderRow = {
      //   id: order.id,
      //   deal: order.deal,
      //   oli: order.Name,
      //   line_type: order?.RecordType?.Name,
      //   nit: order?.Service__r?.Name,
      //   site: order.deal,
      //   address: order.Location__r?.Street_1__c,
      //   city: order.Location__r?.City__c,
      //   state: order.Location__r?.State__c,
      //   service: order.Service_Type__c,
      //   status: order.Service_Order2__r?.Status__c,
      //   foc_date: this.formatDate(order?.Service_Order2__r?.Nitel_FOC_Date__c),
      //   expanded: false,
      //   full_order: order
      // };
    this.ref.detectChanges();
    this.loading = false;
  }
  public formatDate(date: any): string {
    if (date == null || date == undefined) return '-';
    return formatDatePipe(date, 'MM/dd/yyyy', 'en-US');
  }
  // private getData(): Observable<{ lastUpdate: string; orders: Order[] }> {
  //   return this.data = this.orderStore.select(fromStore.getActiveOrders).pipe(
  //     map((orders: Order[]) => {
  //       if (!orders || orders.length === 0) {
  //         return { lastUpdate: '...', orders: [] };
  //       } else {
  //         return { lastUpdate: orders[0].updated_at, orders: orders.map((order) => ({ ...order })) };
  //       }
  //     })
  //   );
  // }

  private getData() {
    this.OrderService.getLastUpdated().then((data) => (this.lastUpdate = data));
  }
}
