<section class="dashboard-page">
  <div class="container">
    <div class="container-top">
      <div class="container-title">
        <h1>Operations Summary <span class="badge">beta</span></h1>
      </div>

      <div class="kpi-flex">
        <div class="kpi-grid-2x">
          <ng-container *ngIf="MTTRAllNOCCasesLifeChart$ | async as chart">
            <app-gauge-value
              class="stat__item"
              [ngClass]="chart.ngClass"
              [data]="chart.data"
            ></app-gauge-value>
          </ng-container>

          <div class="kpi-flex">
            <ng-container *ngIf="yearNitelNpsChart$ | async as chart">
              <app-gauge-value
                class="stat__item"
                [ngClass]="chart.ngClass"
                [data]="chart.data"
              ></app-gauge-value>
            </ng-container>

            <ng-container *ngIf="csatSurveyResultsChart$ | async as chart">
              <app-gauge-value
                class="stat__item"
                [ngClass]="chart.ngClass"
                [data]="chart.data"
              ></app-gauge-value>
            </ng-container>
          </div>
          <!-- 
          <ng-container *ngIf="disconnectedtOrdersChart$ | async as chart">
            <app-single-value
              class="stat__item"
              [ngClass]="chart.ngClass"
              [data]="chart.data"
            ></app-single-value>
          </ng-container> -->

          <div class="kpi-flex">
            <ng-container *ngIf="activeServicesChart$ | async as chart">
              <app-single-value
                class="stat__item"
                [ngClass]="chart.ngClass"
                [data]="chart.data"
              ></app-single-value>
            </ng-container>

            <ng-container *ngIf="inflightOrdersChart$ | async as chart">
              <app-single-value
                class="stat__item"
                [ngClass]="chart.ngClass"
                [data]="chart.data"
              ></app-single-value>
            </ng-container>
          </div>

          <div class="kpi-flex">
            <ng-container *ngIf="newClosedCasesChart$ | async as chart">
              <app-single-value
                class="stat__item"
                [ngClass]="chart.ngClass"
                [data]="chart.data"
              ></app-single-value>
            </ng-container>

            <ng-container *ngIf="ClosedCasesChart$ | async as chart">
              <app-single-value
                class="stat__item"
                [ngClass]="chart.ngClass"
                [data]="chart.data"
              ></app-single-value>
            </ng-container>
          </div>

          <div class="kpi-flex">
            <ng-container *ngIf="oldOpenCareCasesChart$ | async as chart">
              <app-single-value
                class="stat__item"
                [ngClass]="chart.ngClass"
                [data]="chart.data"
              ></app-single-value>
            </ng-container>

            <ng-container *ngIf="openCareCasesChart$ | async as chart">
              <app-single-value
                class="stat__item"
                [ngClass]="chart.ngClass"
                [data]="chart.data"
              ></app-single-value>
            </ng-container>
          </div>

          <div class="kpi-flex">
            <ng-container *ngIf="OldOpenNOCCasesChart$ | async as chart">
              <app-single-value
                class="stat__item"
                [ngClass]="chart.ngClass"
                [data]="chart.data"
              ></app-single-value>
            </ng-container>

            <ng-container *ngIf="OpenNOCCasesChart$ | async as chart">
              <app-single-value
                class="stat__item"
                [ngClass]="chart.ngClass"
                [data]="chart.data"
              ></app-single-value>
            </ng-container>
          </div>

          <div class="kpi-flex">
            <ng-container *ngIf="oldOpenSupportCasesChart$ | async as chart">
              <app-single-value
                class="stat__item"
                [ngClass]="chart.ngClass"
                [data]="chart.data"
              ></app-single-value>
            </ng-container>

            <ng-container *ngIf="openSupportCasesChart$ | async as chart">
              <app-single-value
                class="stat__item"
                [ngClass]="chart.ngClass"
                [data]="chart.data"
              ></app-single-value>
            </ng-container>
          </div>

          <div class="kpi-flex">
            <ng-container *ngIf="oldOpenFinanceCasesChart$ | async as chart">
              <app-single-value
                class="stat__item"
                [ngClass]="chart.ngClass"
                [data]="chart.data"
              ></app-single-value>
            </ng-container>

            <ng-container *ngIf="openFinanceCasesChart$ | async as chart">
              <app-single-value
                class="stat__item"
                [ngClass]="chart.ngClass"
                [data]="chart.data"
              ></app-single-value>
            </ng-container>
          </div>

          <div class="kpi-flex">
            <ng-container *ngIf="EmergencyCareCasesChart$ | async as chart">
              <app-single-value
                class="stat__item"
                [ngClass]="chart.ngClass"
                [data]="chart.data"
              ></app-single-value>
            </ng-container>

            <ng-container *ngIf="openMaintenanceCasesChart$ | async as chart">
              <app-single-value
                class="stat__item"
                [ngClass]="chart.ngClass"
                [data]="chart.data"
              ></app-single-value>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="charts">
        <ng-container *ngIf="caseHistoryByDateChart$ | async as chart">
          <app-line-values
            [data]="chart.data"
            [loading]="chart.loading"
            (navigate)="handleNavigation($event)"
          ></app-line-values>
        </ng-container>
      </div>

      <div class="charts horizontal-bars">
        <ng-container *ngIf="ytdNocCasesClosedCodeChart$ | async as chart">
          <app-horizontal-bars
            [data]="chart.data"
            [loading]="chart.loading"
            (navigate)="handleNavigation($event)"
          ></app-horizontal-bars>
        </ng-container>
      </div>
      <div class="charts horizontal-bars">
        <ng-container *ngIf="MTTRCircuitDownChart$ | async as chart">
          <app-vertical-bars
            [data]="chart.data"
            [loading]="chart.loading"
            (navigate)="handleNavigation($event)"
          ></app-vertical-bars>
        </ng-container>
      </div>
    </div>
  </div>
</section>
