<div class="vertical-bars-modal">
  <div class="vertical-bars-modal__header">
    <h1 class="vertical-bars-modal__title">
      {{ data$()?.title }}
    </h1>

    <button
      mat-icon-button
      class="vertical-bars-modal__close"
      (click)="dialogRef.close()"
    >
        <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="vertical-bars-modal__body">
    <div class="vertical-bars-modal__chart">
      <div [style.height]="chartService$.recommendedHeight$() + 'px'">
        <canvas
          baseChart
          [type]="chartService$.barChartType"
          [data]="chartService$.barChartData$()"
          [options]="chartService$.barChartOptions"
          [plugins]="chartService$.barChartPlugins"
          (chartClick)="chartService$.chartClicked($event)"
        ></canvas>
      </div>
    </div>
  </div>

  <!-- <div class="vertical-bars-modal__footer">
    <a [routerLink]="data$()?.hyperlink">
      {{ data$()?.hyperlinkText || "View " + data$()?.title }}
    </a>
  </div> -->
</div>
