import { Component, OnInit, OnDestroy, Input, OnChanges, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import * as fromStore from 'src/app/store/';
import { Service } from 'src/app/models/service.model';
import { CreateTicketComponent } from 'src/app/tickets/containers';
import { LocationNameComponent } from '../location-name/location-name.component';
import { MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-inventory-detail',
  templateUrl: './inventory-detail.component.html',
  styleUrls: ['./inventory-detail.component.scss']
})
export class InventoryDetailComponent implements OnInit, OnDestroy, OnChanges {
  @Input() browserSize: string;
  @Input() services: Service[];
  @Input() selectedService: Service;
  @Input() activeServices: Service[];

  browserSize$: Observable<string> = this.store.select(fromStore.getBrowserSizeName);
  subscription: Subscription;
  serviceSubscription: Subscription;
  servicesSubscription: Subscription;
  services$: Observable<Service[]>;
  service$: Observable<Service>;

  totalLocations = 0;
  totalServices = 0;
  pendingServices: number = 0;
  inServiceServices: number = 0;

  constructor(
    private store: Store<fromStore.State>,
    private router: Router,
    private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.subscription = this.browserSize$.subscribe((name) => {
      if (name) {
        if (name !== this.browserSize && this.browserSize === 'small') {
          // you're currently changing from a small device to larger device size
          this.router.navigateByUrl('/services');
        }
        this.browserSize = name;
      }
    });

    if (this.browserSize === 'small') {
      this.onSmallScreen();
    }
  }

  ngOnChanges() {
    if (this.browserSize === 'small') {
      this.onSmallScreen();
    } 
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.serviceSubscription) {
      this.serviceSubscription.unsubscribe();
    }
    if (this.servicesSubscription) {
      this.servicesSubscription.unsubscribe();
    }
  }

  onSmallScreen() {
    // this.header.hide();
    this.service$ = this.store.select(fromStore.getCurrentRouterService);
    this.serviceSubscription = this.service$.subscribe((service) => {
      if (service) {
        this.selectedService = service;
        this.store.dispatch(new fromStore.SetSelectedService(service.Id));
        this.changeDetectorRef.detectChanges();
      }
    });
    this.services$ = this.store.select(fromStore.getAllServices);
    this.servicesSubscription = this.services$.subscribe((services) => {
      this.services = services;
    });
  }

  onCreateTicket() {
    // open modal
    const dialogConfig: MatDialogConfig = {
      autoFocus: true,
      minHeight: this.browserSize === 'small' ? '315px' : '380px',
      width: this.browserSize === 'small' ? 'calc(100vw - 20px)' : '650px',
      maxWidth: '650px',
      data: {
        browserSize: this.browserSize,
        originPage: 'Service',
        selectedServiceId: this.selectedService.Id
      },
      disableClose: true
    };

    this.dialog.open(CreateTicketComponent, dialogConfig);
  }

  onEditLocationName(service: Service) {
    // open modal
    const dialogConfig = {
      autoFocus: true,
      minHeight: this.browserSize === 'small' ? '315px' : '380px',
      width: this.browserSize === 'small' ? 'calc(100vw - 20px)' : '650px',
      maxWidth: '650px',
      data: {
        browserSize: this.browserSize,
        originPage: 'Service',
        locationId: service.Z_Location__c,
        locationAddress: service.Z_Location__r.Name,
        banLookupId: service.BanLookupId,
        customerLocationAlias: service.CustomerLocationAlias,
        services: this.services
      },
      disableClose: false
    };

    this.dialog.open(LocationNameComponent, dialogConfig);
  }
}
