import { Component, ChangeDetectionStrategy, Input, OnChanges, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { Order, OrderDetailViews as views } from 'src/app/models/order.model';
import { LineItem } from 'src/app/models/line-item.model';
import { ContentDocumentLink } from 'src/app/models/content-document-link.model';
import * as fromStore from 'src/app/store/';
import * as fromSharedComponents from 'src/app/shared/components';
import { OrderState } from 'src/app/store/reducers/orders.reducer';
import { AttachmentService, FileAPI } from 'src/app/services/attachment.service';

@Component({
  selector: 'orders-detail-shell',
  templateUrl: './detail-shell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrdersDetailShellComponent implements OnChanges, OnDestroy {
  @Input() detailView: string;
  public iconDetail = 'assignment';

  orders$: Observable<Order[]> = this.orderStore.select(fromStore.getAllOrders);
  isLoading$: Observable<boolean> = this.orderStore.select(fromStore.getOrdersLoading);
  order$: Observable<Order>;
  lineItem$: Observable<LineItem>;

  activeOrders$: Observable<Order[]>;
  totalActiveLocations$: Observable<number>;
  serviceOrderForm$: Observable<ContentDocumentLink>;
  formAPIData$: Observable<FileAPI>;

  private isSOFLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(true);
  isSOFLoading$ = this.isSOFLoadingSubject.asObservable();

  subscription: Subscription;
  sofSubscription: Subscription;
  ordersSubscription: Subscription;

  browserSize$: Observable<string> = this.store.select(fromStore.getBrowserSizeName);
  browserSize: string;

  selectedView = views;
  isOrderIdOnRouter = false;
  isLineItemIdOnRouter = false;
  activeOrderSubscription: Subscription;
  orderResCurr: any;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private orderStore: Store<OrderState>,
    private store: Store<fromStore.State>,
    private attachmentService: AttachmentService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.initialize();
  }

  ngOnChanges() {
    this.initialize();
  }

  initialize() {
    this.subscription = this.browserSize$.subscribe((name) => {
      if (name) {
        if (name !== this.browserSize && this.browserSize === 'small') {
          // you're currently changing from a small device to larger device size
          this.router.navigateByUrl('/orders');
        }
        this.browserSize = name;
      }
    });
    this.isOrderIdOnRouter = !!this.route.snapshot.paramMap.get('orderId');
    this.isLineItemIdOnRouter = !!this.route.snapshot.paramMap.get('lineItemId');

    if (this.browserSize === 'small') {
      this.onSmallScreen();
    } else {
      this.onLargeScreen();
    }

    this.isSOFLoadingSubject.next(true);
    this.serviceOrderForm$ = this.orderStore.select(fromStore.getSOFSelected);
    this.sofSubscription = this.serviceOrderForm$.subscribe(
      (form) => {
        if (form && form.ContentDocument !== undefined) {
          this.isSOFLoadingSubject.next(false);
          this.formAPIData$ = this.attachmentService.getFile(form);
        }
        this.changeDetectorRef.detectChanges();
      },
      () => {
        this.isSOFLoadingSubject.next(false);
        this.changeDetectorRef.detectChanges();
      }
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    if (this.sofSubscription) {
      this.sofSubscription.unsubscribe();
    }
    if (this.ordersSubscription) {
      this.ordersSubscription.unsubscribe();
    }
  }

  onLargeScreen() {
    if (this.isOrderIdOnRouter) {
      this.router.navigateByUrl('/orders');
    }
    this.order$ = this.orderStore.select(fromStore.getSelectedOrder);
    this.lineItem$ = this.orderStore.select(fromStore.getSelectedLineItem);
    this.activeOrders$ = this.orderStore.select(fromStore.getActiveOrders);
   
    this.activeOrderSubscription = this.activeOrders$.subscribe((orders) => {
      if (orders) {
        let orderMap = new Map();
        orders.forEach((order) =>{
          let count = orderMap.get(order.Status__c) || 0;
          orderMap.set(order.Status__c, count + 1);
        })
       const orderRes = [...orderMap].reduce((acc, curr) => {
          if(acc.length === 0){acc.push({[curr[0]]: curr[1]})} 
          else {acc[0][curr[0]] = curr[1]}
          return acc
       }, [])
       this.orderResCurr = orderRes[0];
      }
    });
    this.totalActiveLocations$ = this.orderStore.select(fromStore.getTotalActiveLocations);
  }

  onSmallScreen() {
    if (this.isOrderIdOnRouter) {
      this.store.dispatch(new fromStore.LoadOrders());
      this.store.dispatch(new fromStore.LoadServiceOrderForms());
      this.order$ = this.orderStore.select(fromStore.getCurrentRouterOrder);

      if (!this.isLineItemIdOnRouter) {
        this.detailView = this.selectedView.order;
        this.ordersSubscription = this.order$.subscribe((order) => {
          if (order) {
            this.orderStore.dispatch(new fromStore.SetSelectedSOF(order.Id));
            this.changeDetectorRef.detectChanges();
          }
        });
      } else {
        this.detailView = this.selectedView.lineItem;
        this.lineItem$ = this.orderStore.select(fromStore.getCurrentRouterLineItem);
      }
    }
  }

  popupContactInformation(data) {
    // open modal
    const dialogConfig = {
      autoFocus: true,
      minHeight: this.browserSize === 'small' ? '315px' : '380px',
      width: this.browserSize === 'small' ? 'calc(100vw - 20px)' : '650px',
      maxWidth: '650px',
      data: {
        browserSize: this.browserSize,
        originPage: 'Orders',
        title: data.title,
        contact: data.user
      },
      disableClose: false
    };

    this.dialog.open(fromSharedComponents.ContactInformationComponent, dialogConfig);
  }
}
