import { Component, ViewChild, Input, OnChanges, ViewEncapsulation } from "@angular/core";
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexTitleSubtitle,
  ApexPlotOptions,
  ApexDataLabels,
  ApexYAxis,
} from "ng-apexcharts";

export {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexTitleSubtitle,
  ApexPlotOptions,
  ApexDataLabels,
};

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  plotOptions: ApexPlotOptions;
  dataLabels: ApexDataLabels;
  title: ApexTitleSubtitle;
};

@Component({
  selector: "app-trend-histogram",
  templateUrl: "./trend-histogram.component.html",
  styleUrls: ["../../../shared/analytics//analytics.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class TrendHistogram implements OnChanges {
  @ViewChild("chart") chart: ChartComponent;

  @Input() data: any[] = []; // Define series as Input
  @Input() categories: number[] = []; // Define labels as Input
  @Input() chartName: string = "";

  public chartOptions: ChartOptions;

  constructor() {
    this.chartOptions = {
      series: [],
      chart: {
        height: 350,
        type: "line"
      },
      title: {
        align: "center",
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false
      },
      plotOptions: {
        bar: {
          horizontal: true
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (value:number) => {
          if (this.chartName.includes('%')) {
            return value.toLocaleString('en-US') + '%';
          } else {
            return value.toLocaleString('en-US');
          }
        }
      },
      xaxis: {
        categories: this.categories
      },
      yaxis: {
        labels: {
          formatter: (value:number) => {
            if (this.chartName.includes('%')) {
              return value.toLocaleString('en-US') + '%';
            } else {
              return value.toLocaleString('en-US');
            }
          }
        },
      }
    }
  }

  ngOnChanges() {
    // Update chart options when @Input properties change
    this.chartOptions.series = this.data;
    this.chartOptions.xaxis = { 
      type: "datetime"
    };
    this.chartOptions.series = [{
      data: this.categories
    }],
    this.chartOptions.title = {
      text: this.chartName,
      align: "center",
      margin: 10
    };
  }
}
