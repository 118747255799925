<div class="single-value-item stats">
  <div class="single-value-item__header" >
    <h1 class="single-value-item__title">
      {{ data.title }}
    </h1>
    <!-- <div>
      <button
        mat-icon-button
        class="single-value-item__expand"
        (click)="openModal()"
      >
        <mat-icon>fullscreen</mat-icon>
      </button>
    </div> -->
  </div>
  <div class="single-value-item__body" [appTooltip]="tooltipTemplate" #modalContent>
    {{ data.value }}
  </div>
  
 <!-- TODO: In Progress, bring back when all the URLs are in place -->
  <!-- <div class="single-value-item__footer">
    <a [routerLink]="data.hyperlink">
      {{ data.hyperlinkText || "View " + data.title }}
    </a>
  </div> -->
</div>

<ng-template #tooltipTemplate>
  <div>
    <strong> {{ data.value }}</strong>
  </div>
</ng-template>
