import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, inject } from '@angular/core';

@Component({
  selector: 'app-gauge-value-modal',
  templateUrl: './gauge-value-modal.component.html',
  styleUrls: ['./gauge-value-modal.component.scss']
})
export class GaugeValueModalComponent {

  public readonly dialogRef = inject(MatDialogRef<GaugeValueModalComponent>)
  public readonly data = inject(MAT_DIALOG_DATA)

  public thresholdConfig: { [key: string]: { color: string, bgOpacity?: number } };
  public markerConfig: { [key: string]: { color: string, size: number, type: string, label: string | number } };
  
  ngOnInit(): void {
    this.thresholdConfig = {
      '0': { color: 'green', bgOpacity: 0.2 },
      [this.data.maxValue * 0.4]: { color: 'orange', bgOpacity: 0.2 },
      [this.data.maxValue * 0.755]: { color: 'red', bgOpacity: 0.2 }
    };

    this.markerConfig = {
      '0': { color: '#555', size: 8, label: '0', type: 'line' },
      [this.data.maxValue * 0.25]: { color: '#555', size: 8, label: `${Math.round(this.data.maxValue * 0.25)}`, type: 'line' },
      [this.data.maxValue * 0.5]: { color: '#555', size: 8, label: `${Math.round(this.data.maxValue * 0.5)}`, type: 'line' },
      [this.data.maxValue * 0.75]: { color: '#555', size: 8, label: `${Math.round(this.data.maxValue * 0.75)}`, type: 'line' },
      [this.data.maxValue]: { color: '#555', size: 8, label: `${Math.round(this.data.maxValue)}`, type: 'line' },
      [this.data.value]: { color: '#000', size: 10, label: `${Math.round(this.data.value)}`, type: 'triangle' }
    };
  }

}
