import {
  Component,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from "@angular/core";
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexTitleSubtitle,
  ApexPlotOptions,
  ApexDataLabels,
  ApexYAxis,
} from "ng-apexcharts";

export {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexTitleSubtitle,
  ApexPlotOptions,
  ApexDataLabels,
};

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  plotOptions: ApexPlotOptions;
  title: ApexTitleSubtitle;
};

@Component({
  selector: "app-horizontal-bar-graph",
  templateUrl: "./horizontal-bar-graph.component.html",
  styleUrls: ["../../../shared/analytics//analytics.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HorizontalBarGraph {
  @ViewChild("chart") chart: ChartComponent;

  @Input() data: any[] = []; // Define series as Input
  @Input() categories: string[] = []; // Define labels as Input
  @Input() chartName: string = "";

  @Output() changeDevice = new EventEmitter<string>();

  public chartOptions: ChartOptions;

  constructor() {
    this.chartOptions = {
      series: [],
      chart: {
        height: 350,
        type: "bar",
        events: {
          dataPointSelection: (event: any, chartContext: any, config: any) => {
            this.onChangeDevice(config);
          }
        }
      },
      plotOptions: {
        bar: {
          horizontal: false
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (value:number) => {
          if (this.chartName.includes('%')) {
            return value.toLocaleString('en-US') + '%';
          } else {
            return value.toLocaleString('en-US');
          }
        }
      },
      xaxis: {
        categories: this.categories
      },
      yaxis: {
        labels: {
          formatter: (value:number) => {
            if (this.chartName.includes('%')) {
              return value.toLocaleString('en-US') + '%';
            } else {
              return value.toLocaleString('en-US');
            }
          }
        }
      },
      title: {
        text: this.chartName,
        align: "center",
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false
      },
    };
  }

  ngOnChanges() {
    // Update chart options when @Input properties change
    this.chartOptions.xaxis = {categories: this.categories};
    this.chartOptions.series = this.data;
    this.chartOptions.title.text = this.chartName;
  }

  onChangeDevice(config) {
    const selectedPointIndex = config.dataPointIndex;
    this.changeDevice.emit(
      this.chartOptions.xaxis.categories[selectedPointIndex]
    );
  }
}
