import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription, map } from 'rxjs';
import { tap } from 'rxjs/operators';

import * as fromStore from 'src/app/store/';
import { Ticket } from 'src/app/models/ticket.model';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';

import { CreateTicketComponent } from '../create-ticket/create-ticket.component';

@Component({
  selector: 'app-tickets-shell',
  styleUrls: ['tickets-shell.component.scss'],
  templateUrl: './tickets-shell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketsShellComponent implements OnInit, OnDestroy {
  tickets$: Observable<Ticket[]>;
  ticketsValue: Ticket[] = [];
  isLoading$: Observable<boolean> = this.store.select(fromStore.getTicketsLoading);
  isLoaded$: Observable<boolean> = this.store.select(fromStore.getTicketsLoading);
  selectedTicket$: Observable<Ticket>;
  browserSize$: Observable<string> = this.store.select(fromStore.getBrowserSizeName);

  selectedTicket: Ticket = null;
  subscription: Subscription;
  ticketsSubscription: Subscription;
  browserSize: string;

  ticketCsvData: any[] = [];
  csvFileName = 'Tickets';

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private store: Store<fromStore.State>,
    private dialog: MatDialog
  ) {
    this.subscription = this.browserSize$.subscribe((name) => {
      if (name) {
        this.browserSize = name;
      }
    });
  }

  ngOnInit() {
    this.store.dispatch(new fromStore.LoadTickets());
    this.tickets$ = this.store.select(fromStore.getAllTickets);
    this.filteredTickets$ = this.tickets$;
  }

  ngAfterViewInit() {
    this.formatTicketCsvData();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onWindowResize() {
    this.changeDetectorRef.detectChanges();
  }

  reloadTickets() {
    this.store.dispatch(new fromStore.LoadTickets());
    this.tickets$ = this.store.select(fromStore.getAllTickets);

    this.changeDetectorRef.detectChanges();
  }

  onSelectTicket(ticket: Ticket) {
    this.store.dispatch(new fromStore.SetSelectedTicket(ticket.Id));
    this.selectedTicket$ = this.store.select(fromStore.getSelectedTicket);
  }

  openDialog() {
    // Dialog to create tickets
    const dialogConfig = {
      autoFocus: true,
      minHeight: this.browserSize === 'small' ? '315px' : '380px',
      width: this.browserSize === 'small' ? 'calc(100vw - 20px)' : '650px',
      maxWidth: '650px',
      data: {
        browserSize: this.browserSize
      },
      disableClose: true
    };

    const createTicketDialog = this.dialog.open(CreateTicketComponent, dialogConfig);
    createTicketDialog.afterClosed().subscribe(() => {
      this.tickets$ = this.store.select(fromStore.getAllTickets);
      this.changeDetectorRef.detectChanges();
    });
  }

  filteredTickets$: Observable<Ticket[]>;
  onTimeRangeChanged(timeRange: any) {
    this.filteredTickets$ = this.tickets$.pipe(
      map((tickets) => {
        return this.filterTicketsByTimeRange(tickets, timeRange);
      })
    );
  }
  
  filterTicketsByTimeRange(tickets: Ticket[], timeRange: string): Ticket[] {
    const currentTime = Date.now();
    switch (timeRange) {
      case "6hours":
        return tickets.filter(
          (ticket) =>
            new Date(ticket.CreatedDate).getTime() >
            currentTime - 6 * 60 * 60 * 1000
        );
      case "1day":
        return tickets.filter(
          (ticket) =>
            new Date(ticket.CreatedDate).getTime() >
            currentTime - 24 * 60 * 60 * 1000
        );
      case "1month":
        return tickets.filter(
          (ticket) =>
            new Date(ticket.CreatedDate).getTime() >
            currentTime - 30 * 24 * 60 * 60 * 1000
        );
      case "3months":
        return tickets.filter(
          (ticket) =>
            new Date(ticket.CreatedDate).getTime() >
            currentTime - 90 * 24 * 60 * 60 * 1000
        );
      default:
        return tickets;
    }
  }

  formatTicketCsvData() {
    this.ticketsSubscription = this.tickets$.subscribe((tickets) => {
      const csvData: any[] = [];

      tickets.forEach((ticket) => {
        const businessPurpose =
          ticket.Business_Purpose__c && ticket.Business_Purpose__c.length > 0
            ? ticket.Business_Purpose__c
            : ticket.RecordType.Name;

        const row = {
          'Ticket Number': ticket.CaseNumber,
          'Business Purpose': businessPurpose,
          Title: ticket.Subject,
          Status: ticket.Status,
          Location: ticket.Service_Location_Address__c,
          Service: ticket.Service__r ? ticket.Service__r.Name : null,
          'Maintenance Date': ticket.Maintenance_Date__c ? moment(ticket.Maintenance_Date__c).format('L') : null
        };
        csvData.push(row);
        this.changeDetectorRef.detectChanges();
      });
      this.ticketCsvData = csvData;
    });
  }
}
