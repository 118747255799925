import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import * as fromStore from 'src/app/store/';
import { BanLookupService } from 'src/app/services/ban-lookup.service';
import { BanLookup } from 'src/app/models/ban-lookup.model';
import { Service } from 'src/app/models/service.model';

@Component({
  selector: 'app-location-name',
  templateUrl: './location-name.component.html',
  styleUrls: ['./location-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationNameComponent implements OnInit {
  dialogWidth: string;
  dialogHeight: string;

  services: Service[] = [];
  locationAddress: string;
  hasError = false;
  subscription: Subscription;

  isFormSubmitted = false;
  locationNameFormGroup: UntypedFormGroup = this.fb.group({
    banLookupId: this.fb.control('', Validators.required),
    locationNameAlias: this.fb.control('', Validators.required)
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData,
    public dialogRef: MatDialogRef<LocationNameComponent>,
    private changeDetectorRef: ChangeDetectorRef,
    private banLookupService: BanLookupService,
    private store: Store<fromStore.State>,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.locationAddress = this.dialogData.locationAddress;
    this.locationNameFormGroup.controls.banLookupId.setValue(this.dialogData.banLookupId);
    this.locationNameFormGroup.controls.locationNameAlias.setValue(this.dialogData.customerLocationAlias);
    this.services = this.dialogData.services;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSubmit() {
    const request = {
      Id: this.locationNameFormGroup.value.banLookupId,
      Customer_Location_Alias__c: this.locationNameFormGroup.value.locationNameAlias
    };
    this.updateBanLookup(request);
  }

  onDelete() {
    const request = {
      Id: this.locationNameFormGroup.value.banLookupId,
      Customer_Location_Alias__c: ' '
    };
    this.updateBanLookup(request);
  }

  updateBanLookup(value: BanLookup) {
    this.hasError = false;

    // for cache / state management
    const updatedServices = this.services.slice().map((s) => {
      const newServices = { ...s };
      if (newServices.BanLookupId === value.Id) {
        newServices.CustomerLocationAlias = value.Customer_Location_Alias__c;
      }
      return newServices;
    });

    this.subscription = this.banLookupService.updateBanLookup(value).subscribe(
      () => {
        this.store.dispatch(new fromStore.UpdateServicesBanLookup(updatedServices));
        this.changeDetectorRef.detectChanges();
        this.closeDialog();
      },
      () => {
        this.hasError = true;
        this.changeDetectorRef.detectChanges();
      }
    );
  }
}
