import {
  LoadTickets,
  LoadTicketsFail,
  LoadTicketsSuccess,
} from "../../../store";
import { Ticket } from "src/app/models/ticket.model";
import { ChartService } from "./chart.service";
import { SingleValueKPI } from "../../types";
import { Injectable } from "@angular/core";
import { map } from "rxjs";

@Injectable()
export class OpenNOCCasesChartService extends ChartService<SingleValueKPI> {
  public readonly chart$ = this.chartsDataLoader({
    request: LoadTickets,
    failure: LoadTicketsFail,
    success: LoadTicketsSuccess,
  }).pipe(
    map(({ loading, data }) => {
      const dataValue: number =
        data?.filter((s) => this.filterNOC(s)).length || 0;

      return {
        ngClass: [],
        loading,
        data: {
          id: "Open NOC cases",
          title: "All Open NOC Tickets",
          value: dataValue,
          hyperlink: "/tickets", // need flags
        },
      };
    })
  );

  private filterNOC(ticket: Ticket): boolean {
    return (
      ticket.Business_Purpose__c?.includes('NOC') ||
      ticket.RecordType?.Name === 'NOC'
    );
  }  
  
}
