import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, effect, inject, OnInit, ViewChild } from '@angular/core';
import { ChartService } from '../services/chart.service';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'app-line-values-modal',
  templateUrl: './line-values-modal.component.html',
  styleUrls: ['./line-values-modal.component.scss'],
  providers: [ChartService],
})
export class LineValuesModalComponent implements OnInit {

  @ViewChild(BaseChartDirective) chart: BaseChartDirective;
  public readonly dialogRef = inject(MatDialogRef<LineValuesModalComponent>)
  public readonly data = inject(MAT_DIALOG_DATA)

  //#region     BEGIN ChartService
  public readonly chartService$ = inject(ChartService)
  public get data$() { return this.chartService$.data$ }
  public readonly updateChartEffect$ = effect(() => {
    this.chartService$.data$.set({
      value: [
        { label: 'Jan', value: 10, link: '/jan' },
        { label: 'Feb', value: 20, link: '/feb' },
        { label: 'Mar', value: 30, link: '/mar' },
        { label: 'Apr', value: 40, link: '/apr' },
      ],
      id: "",
      title: ""
    });
    this.chart?.update();
  });
  //#endregion  END ChartService

  ngOnInit(): void {
    this.data$.set(this.data)
  }

}
