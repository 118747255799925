import { ChartConfiguration, ChartData } from "chart.js";
import DataLabelsPlugin from "chartjs-plugin-datalabels";

//#region     BEGIN OPTIONS
const defaultChartOptions = (): ChartConfiguration["options"] => ({
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      beginAtZero: true,
      display: true,
      grid: {
        display: true,
      },
      ticks: {
        padding: 20,
      },
    },
  },
  responsive: true, 
  maintainAspectRatio: false, 
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      anchor: "end",
      align: "end",
      font: {
        size: 12,
        weight: "bolder",
        family: '"open-sans", sans-serif',
      },
      color: "#000",
      formatter: (value) => {
        return value;
      },
    },
  },
});
//#endregion  END OPTIONS

//#region     BEGIN DATA
const defaultChartData = (): ChartData<'bar'> => ({
  labels: [],
  datasets: [
    {
      data: [],
      label: "Pending FOC Date",
      barPercentage: 0.7,
      borderWidth: 1,
      hoverBorderWidth: 1,
    },
  ],
});
//#endregion  BEGIN DATA

//#region     BEGIN DATA
const defaultChartPlugins = (): any[] => [DataLabelsPlugin];
//#endregion  BEGIN DATA

export const chartDefaults = Object.freeze({
  plugins: defaultChartPlugins,
  options: defaultChartOptions,
  data: defaultChartData,
});
