import { Component, inject } from "@angular/core";
import { Router } from "@angular/router";
import { ActiveServicesChartService } from "./services/active-services-chart.service";
import { DisconnectedOrdersChartService } from "./services/disconnected-orders-chart.service";
import { InflightOrdersChartService } from "./services/inflight-orders-chart.service";
import { OpenSuportCasesChartService } from "./services/open-support-cases-chart.service";
import { OldOpenSupportCasesChartService } from "./services/old-open-support-cases-chart.service";
import { OpenMaintenanceCasesChartService } from "./services/open-maintenance-cases-chart.service";
import { OpenCareCasesChartService } from "./services/open-care-cases-chart.service";
import { OldOpenCareCasesChartService } from "./services/old-open-care-cases-chart.service";
import { OpenFinanceCasesChartService } from "./services/open-finance-cases-chart.service";
import { OldOpenFinanceCasesChartService } from "./services/old-open-finance-cases-chart.service";
import { YtdNocCasesClosedCodeChartService } from "./services/ytd-noc-cases-closed-code-chart.service";
import { CaseHistoryByDateChartService } from "./services/case-history-by-date-chart.service";
import { ClosedCasesChartService } from "./services/old-closed-cases-chart.service";
import { NewClosedCasesChartService } from "./services/new-closed-cases-chart.service";
import { EmergencyCasesChartService } from "./services/open-emergency-cases-chart.service";
import { OpenNOCCasesChartService } from "./services/open-NOC-cases-chart.service";
import { OldOpenNOCCasesChartService } from "./services/old-open-NOC-cases-chart.service";
import { MTTRCircuitDownChartService } from "./services/mttr-circuit-down-chart.service";
import { MTTRAllNOCCasesLifeChartService } from "./services/mttr-all-noc-cases-life-chart.service";
import { YearNitelNpsChartService } from "./services/year-nitel-nps-chart.service";
import { CSATSurveyResultsChartService } from "./services/csta-survey-results-chart.service";

@Component({
  selector: "app-operations-dashboard",
  templateUrl: "./operations-page.component.html",
  styleUrls: ["./operations-page.component.scss"],
  providers: [
    ActiveServicesChartService,
    InflightOrdersChartService,
    DisconnectedOrdersChartService,
    OpenSuportCasesChartService,
    OldOpenSupportCasesChartService,
    OpenMaintenanceCasesChartService,
    OpenCareCasesChartService,
    OldOpenCareCasesChartService,
    OpenFinanceCasesChartService,
    OldOpenFinanceCasesChartService,
    YtdNocCasesClosedCodeChartService,
    CaseHistoryByDateChartService,
    ClosedCasesChartService,
    NewClosedCasesChartService,
    EmergencyCasesChartService,
    OpenNOCCasesChartService,
    OldOpenNOCCasesChartService,
    MTTRCircuitDownChartService,
    MTTRAllNOCCasesLifeChartService,
    YearNitelNpsChartService,
    CSATSurveyResultsChartService,
  ],
})
export class OperationsComponent {
  public readonly activeServicesChart$ = inject(ActiveServicesChartService).chart$;
  public readonly inflightOrdersChart$ = inject(InflightOrdersChartService).chart$;
  public readonly disconnectedtOrdersChart$ = inject(DisconnectedOrdersChartService).chart$;
  public readonly openSupportCasesChart$ = inject(OpenSuportCasesChartService).chart$;
  public readonly oldOpenSupportCasesChart$ = inject(OldOpenSupportCasesChartService).chart$;
  public readonly openMaintenanceCasesChart$ = inject(OpenMaintenanceCasesChartService).chart$;
  public readonly openCareCasesChart$ = inject(OpenCareCasesChartService).chart$;
  public readonly oldOpenCareCasesChart$ = inject(OldOpenCareCasesChartService).chart$;
  public readonly openFinanceCasesChart$ = inject(OpenFinanceCasesChartService).chart$;
  public readonly oldOpenFinanceCasesChart$ = inject(OldOpenFinanceCasesChartService).chart$;
  public readonly ytdNocCasesClosedCodeChart$ = inject(YtdNocCasesClosedCodeChartService).chart$;
  public readonly caseHistoryByDateChart$ = inject(CaseHistoryByDateChartService).chart$;
  public readonly ClosedCasesChart$ = inject(ClosedCasesChartService).chart$;
  public readonly newClosedCasesChart$ = inject(NewClosedCasesChartService).chart$;
  public readonly EmergencyCareCasesChart$ = inject(EmergencyCasesChartService).chart$;
  public readonly OpenNOCCasesChart$ = inject(OpenNOCCasesChartService).chart$;
  public readonly OldOpenNOCCasesChart$ = inject(OldOpenNOCCasesChartService).chart$;
  public readonly MTTRCircuitDownChart$ = inject(MTTRCircuitDownChartService).chart$;
  public readonly MTTRAllNOCCasesLifeChart$ = inject(MTTRAllNOCCasesLifeChartService).chart$;
  public readonly yearNitelNpsChart$ = inject(YearNitelNpsChartService).chart$;
  public readonly csatSurveyResultsChart$ = inject(CSATSurveyResultsChartService).chart$;
  private readonly router = inject(Router);
  
  public readonly loading: boolean = false;

  public handleNavigation(link: string): void {
    this.router.navigate([link]);
  }

}
