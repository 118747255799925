<section class="dashboard-page">
  <div class="container">
    <div class="container-top">
      <div class="container-title">
        <h1>Orders Summary</h1>
      </div>
    </div>

    <div class="loading-spinner" *ngIf="loadingAllPage">
      <mat-spinner [diameter]="50"></mat-spinner>
    </div>
    <div class="summary" *ngIf="!loadingAllPage">
      <mat-grid-list
        class="dashboard-grid"
        [cols]="breakpoint"
        gutterSize="10px"
        rowHeight="100px"
        (window:resize)="onResize($event)"
      >
        <mat-grid-tile class="tile-widget" rowspan="5">
          <app-service-overview
            title="Service Overview"
            [list]="serviceOverviewList"
            [loading]="loading"
            extraClass="overview"
            (navigate)="navigate($event)"
          ></app-service-overview>
        </mat-grid-tile>

        <mat-grid-tile class="tile-widget bullet-row" height="120px">
          <app-bullet-status
            bulletClass="preparing-order-vendor"
            title="Preparing Order for Vendor"
            [value]="countPreparing"
            [loading]="loading"
          ></app-bullet-status>
        </mat-grid-tile>
        <mat-grid-tile class="tile-widget bullet-row">
          <app-bullet-status
            bulletClass="count-implementation"
            title="Implementation"
            [value]="countImplementation"
            [loading]="loading"
          ></app-bullet-status>
        </mat-grid-tile>
        <mat-grid-tile class="tile-widget bullet-row">
          <app-bullet-status
            bulletClass="count-installed"
            title="Installed"
            [value]="countInService"
            [loading]="loading"
          ></app-bullet-status>
        </mat-grid-tile>

        <mat-grid-tile class="tile-widget" [colspan]="breakpoint > 1 ? 3 : 1" rowspan="4">
          <app-circuit-milestones
            [data]="milestonesData"
            [loading]="milestonesDataLoading"
            (navigate)="navigate($event)"
          ></app-circuit-milestones>
        </mat-grid-tile>

        <mat-grid-tile class="tile-widget" rowspan="3">
          <app-service-overview
            title="Activation Status"
            [list]="startOfServiceList"
            [loading]="loading"
            extraClass="bottom"
            (navigate)="navigate($event)"
          ></app-service-overview>
        </mat-grid-tile>
        <mat-grid-tile class="tile-widget" rowspan="3">
          <app-service-overview
            title="In-progress Services"
            emptyMessage="There are currently no in-flight orders"
            [list]="inFlightServiceList"
            [loading]="inFlightServiceLoading"
            extraClass="bottom scroll"
            (navigate)="navigate($event)"
          ></app-service-overview>
        </mat-grid-tile>
        <mat-grid-tile class="tile-widget" rowspan="3" [colspan]="breakpoint > 1 ? 2 : 1">
          <app-pending-foc-dates
            [data]="pendingFocDateList"
            [total]="pendingFocDateTotal"
            [loading]="pendingFocDateLoading"
            (navigate)="navigate($event)"
          ></app-pending-foc-dates>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  </div>
</section>
