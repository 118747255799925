import {
  LoadOrders,
  LoadOrdersFail,
  LoadOrdersSuccess,
} from "../../../store";
import { LineItem } from "src/app/models/line-item.model";
import { Order } from "src/app/models/order.model";
import { ChartService } from "./chart.service";
import { SingleValueKPI } from "../../types";
import { Injectable } from "@angular/core";
import { map } from "rxjs";
import { mergeMap, of } from "rxjs";

@Injectable()
export class InflightOrdersChartService extends ChartService<SingleValueKPI> {
  public readonly chart$ = this.chartsDataLoader({
    request: LoadOrders,
    success: LoadOrdersSuccess,
    failure: LoadOrdersFail,
  }).pipe(
    map(({ loading, data }) => {
      const dataValue = (data || [])
        .filter(order => this.filterByStatus(order))
        .flatMap(order => order.Customer_Order_OLI__r?.records || [])
        .filter(record => this.filterOrderRecord(record))
        .length;

      const colorClass =
        dataValue > 50
          ? "chart-text-color--danger"
          : dataValue > 25
          ? "chart-text-color--warn"
          : "";

      return {
        ngClass: [colorClass],
        loading,
        data: {
          id: "Inflight Orders",
          title: "Inflight Orders",
          value: dataValue,
          hyperlink: "/orders", // need flags
        },
      };
    })
  );

  private filterByStatus({ Status__c }: Order): boolean {
    return Status__c?.trim().toUpperCase() === "IN PROGRESS";
  }

  private filterOrderRecord({ Line_Item_Type__c }: LineItem): boolean {
    return Line_Item_Type__c?.trim().toUpperCase() === "NEW";
  }
}
