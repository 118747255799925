export const api = {
  forgotPasswordURL: `/reset_request`,
  loginURL: `/login`,
  resetPasswordURL: `/reset_password`,
  validateTokenURL: `/token_status`,

  contactsURL: '/contacts',
  loggedInContactURL: '/contacts/logged_in_contact',
  usersURL: '/users',

  attachmentURL: '/attachments',
  invoiceAttachmentURL: `/attachments/invoices`,
  sofAttachmentURL: '/attachments/sof',

  lineItemsURL: `/line-items`,
  ordersURL: `/orders`,
  ordersV2URL: `/ordersv2`,
  servicesURL: `/services`,

  taskAssignmentsURL: '/task-assignments',
  tasksURL: '/tasks',

  invoicesURL: `/invoices`,
  paymentsURL: `/payments`,

  nodesDownURL: `/nodes/down`,
  aggregatesURL: "/iq/ticket_insight",

  commentsURL: '/comments',
  ticketsURL: '/tickets',

  banLookupsURL: '/banlookups',
  locationsURL: '/locations',

  addAttachmentURL: '/addAttachment',
  getAttachmentsUrl: '/getAttachments',

  getNitelIQ: '/IQ/ticket_insight',
  getUnusualMetrics: '/anomalies/unusual-metrics',
  getMetricDetails: '/insights/metrics/details',
  getAggregatedMetrics: '/insights/metrics/aggregate',
  surveyURL: `/surveys`,
};
