<div class="horizontal-bars-item stats">
  <div class="horizontal-bars-item__header">
    <h1 class="horizontal-bars-item__title">
      {{ data$()?.title }}
    </h1>
    <div>
      <button
        mat-icon-button
        class="horizontal-bars-item__expand"
        (click)="downloadXlsx()"
      >
        <mat-icon>file_open</mat-icon>
      </button>
      <button
        mat-icon-button
        class="horizontal-bars-item__expand"
        (click)="openModal()"
      >
        <mat-icon>fullscreen</mat-icon>
      </button>
    </div>
  </div>
  <div class="horizontal-bars-item__body">
    <section class="card">
      <div class="widget-container">
        <div class="loading-spinner" *ngIf="loading; else Chart">
          <mat-spinner [diameter]="50"></mat-spinner>
        </div>

        <ng-template #Chart>
          <div class="horizontal-bars-item__chart" #modalContent>
            <div [style.height]="chartService$.recommendedHeight$() + 'px'">
              <canvas
                baseChart
                [type]="chartService$.barChartType"
                [data]="chartService$.barChartData$()"
                [options]="chartService$.barChartOptions"
                [plugins]="chartService$.barChartPlugins"
                (chartClick)="chartService$.chartClicked($event)"
              ></canvas>
            </div>
          </div>
        </ng-template>
      </div>
    </section>
  </div>

  <!-- <div class="horizontal-bars-item__footer">
    <a [routerLink]="data$()?.hyperlink">
      {{ data$()?.hyperlinkText || "View " + data$()?.title }}
    </a>
  </div> -->
</div>
