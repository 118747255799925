<div class="gauge-value-modal">
  <div class="gauge-value-modal__header">
    <h1 class="gauge-value-modal__title">
      {{ data.title }}
    </h1>
    <button
      mat-icon-button
      class="gauge-value-modal__close"
      (click)="dialogRef.close()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="gauge-value-modal__body">
    <ngx-gauge
      [value]="data.value"
      [min]="data.minValue"
      [max]="data.maxValue"
      [type]="'arch'"
      [cap]="'butt'"
      [thick]="12"
      [margin]="20"
      [thresholds]="data.thresholds"
      [label]="data.numberLabel"
      [markers]="data.markers"
    />
  </div>

  <!-- <div class="gauge-value-modal__footer">
    <a [routerLink]="data.hyperlink">
      {{ data.hyperlinkText || "View " + data.title }}
    </a>
  </div> -->
</div>
