import { Component, Input, OnDestroy, ElementRef, ViewChild, Renderer2, QueryList, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { CreateTicketComponent } from 'src/app/tickets/containers';
import * as fromStore from 'src/app/store/';

@Component({
  selector: 'app-help-cta',
  templateUrl: './help-cta.component.html',
  styleUrls: ['./help-cta.component.scss']
})
export class HelpCtaComponent implements OnDestroy {
  @Input() originPage: string;
  @Input() hasInvoice: boolean;

  subscription: Subscription;
  browserSize$: Observable<string>;
  browserSize: string;
  showBlimp = false;

  @ViewChild('toggleHelpBlimpButton', { static: false }) toggleHelpBlimpButton: ElementRef;
  @ViewChildren('helpBlimpView') helpBlimpView: QueryList<ElementRef>;

  constructor(private dialog: MatDialog, private renderer: Renderer2, private store: Store<fromStore.State>) {
    this.browserSize$ = this.store.select(fromStore.getBrowserSizeName);
    this.subscription = this.browserSize$.subscribe((name) => {
      if (name) {
        this.browserSize = name;
      }
    });

    if (this.browserSize !== 'small') {
      // Help Blimp Window will hide if clicked outside (desktop only)
      this.renderer.listen('window', 'click', (e: Event) => {
        if (e.target !== this.toggleHelpBlimpButton.nativeElement) {
          let isEventInElement = false;

          // Loops through all divs surrounding help blimp
          this.helpBlimpView.forEach((div: ElementRef) => {
            // If event click is in the same div as help blimp
            if (e.target === div.nativeElement) {
              isEventInElement = true;
            }
          });

          // If pointer wasn't in any of the divs, hide help blimp
          if (!isEventInElement) {
            this.showBlimp = false;
          }
        }
      });
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onCreateTicket() {
    // open modal
    const dialogConfig = {
      autoFocus: true,
      minHeight: this.browserSize === 'small' ? '315px' : '380px',
      width: this.browserSize === 'small' ? 'calc(100vw - 20px)' : '650px',
      maxWidth: '650px',
      data: {
        browserSize: this.browserSize,
        originPage: this.originPage
      },
      disableClose: true
    };

    const createTicketDialog = this.dialog.open(CreateTicketComponent, dialogConfig);
    createTicketDialog.afterClosed().subscribe();
  }

  toggleBlimp() {
    this.showBlimp = !this.showBlimp;
  }
}
