import { Component, ChangeDetectionStrategy, OnChanges, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { Order } from '../../../models/order.model';
import * as fromStore from '../../../store/';
import { OrderState } from '../../../store/reducers/orders.reducer';

@Component({
  selector: 'orders-top',
  templateUrl: 'orders-top.component.html',
  styleUrls: ["orders-top.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrdersTopComponent implements OnChanges, OnDestroy {
  activeOrders$: Observable<Order[]>; //
  totalActiveLocations$: Observable<number>;
  public iconDetail = 'assignment';

  subscription: Subscription;
  ordersSubscription: Subscription;

  browserSize$: Observable<string> = this.store.select(fromStore.getBrowserSizeName);
  browserSize: string;

  activeOrderSubscription: Subscription;
  orderResCurr: any;

  constructor(
    private orderStore: Store<OrderState>,
    private store: Store<fromStore.State>,
    private router: Router
  ) {}

  ngOnInit() {
    this.initialize();
  }

  ngOnChanges() {
    this.initialize();
  }

  initialize() {
    this.subscription = this.browserSize$.subscribe((name) => {
      if (name) {
        if (name !== this.browserSize && this.browserSize === 'small') {
          // you're currently changing from a small device to larger device size
          this.router.navigateByUrl('/orders');
        }
        this.browserSize = name;
      }
    });

    if (this.browserSize === 'small') {
      // this.onSmallScreen();
    } else {
      this.onLargeScreen();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    if (this.ordersSubscription) {
      this.ordersSubscription.unsubscribe();
    }
  }

  onLargeScreen() {
    this.activeOrders$ = this.orderStore.select(fromStore.getActiveOrders);
   
    this.activeOrderSubscription = this.activeOrders$.subscribe((orders) => {
      if (orders) {
        let orderMap = new Map();
        orders.forEach((order) =>{
          let count = orderMap.get(order.Status__c) || 0;
          orderMap.set(order.Status__c, count + 1);
        })
       const orderRes = [...orderMap].reduce((acc, curr) => {
          if(acc.length === 0){acc.push({[curr[0]]: curr[1]})} 
          else {acc[0][curr[0]] = curr[1]}
          return acc
       }, [])
       this.orderResCurr = orderRes[0];
      }
    });
    this.totalActiveLocations$ = this.orderStore.select(fromStore.getTotalActiveLocations);
  }

  // onSmallScreen() {
  //   if (this.isOrderIdOnRouter) {
  //     this.store.dispatch(new fromStore.LoadOrders());
  //     this.store.dispatch(new fromStore.LoadServiceOrderForms());

  //   }
  // }

  // popupContactInformation(data) {
  //   // open modal
  //   const dialogConfig = {
  //     autoFocus: true,
  //     minHeight: this.browserSize === 'small' ? '315px' : '380px',
  //     width: this.browserSize === 'small' ? 'calc(100vw - 20px)' : '650px',
  //     maxWidth: '650px',
  //     data: {
  //       browserSize: this.browserSize,
  //       originPage: 'Orders',
  //       title: data.title,
  //       contact: data.user
  //     },
  //     disableClose: false
  //   };

  //   this.dialog.open(fromSharedComponents.ContactInformationComponent, dialogConfig);
  // }
}
