import { Component, ViewChild, ElementRef, ViewChildren, QueryList, ChangeDetectionStrategy } from '@angular/core';
import { trigger, state, style, animate, transition, query, animateChild, stagger } from '@angular/animations';
import { Subscription } from 'rxjs';

import { AuthService } from 'src/app/services/auth.service';
import { Navigation, nav, nav3 } from 'src/app/models/navigation.model';

@Component({
  selector: 'app-account-menu',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './account-menu.component.html',
  styleUrls: ['./account-menu.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
    '(document:pan)': 'onPan()'
  },
  animations: [
    trigger('parentAnimation', [
      state(
        'true',
        style({
          opacity: 1
        })
      ),
      state(
        'false',
        style({
          opacity: 0
        })
      ),
      transition('false => true', [
        animate('200ms'),
        query('@childAnimation', [animateChild(), stagger(50, animateChild())])
      ]),
      transition('true => false', [animate('50ms')])
    ]),
    trigger('modalStateDisplay', [
      state('true', style({ display: 'block' })),
      state('false', style({ display: 'none' })),
      transition('0 => 1', animate('0ms ease')),
      transition('1 => 0', animate('0ms 200ms ease'))
    ]),
    trigger('childAnimation', [
      state(
        'true',
        style({
          opacity: 1
        })
      ),
      state(
        'false',
        style({
          opacity: 0
        })
      ),
      transition('*=>true', [animate(250)])
    ])
  ]
})
export class AccountMenuComponent {
  toggleMenu = false;
  vigilURL = this.authService.vigilURL;
  subscription: Subscription;
  nav: Navigation[] = nav;
  nav3: Navigation[] = nav3;

  @ViewChild('mobileMenuButton', { static: true }) mobileMenuButton: ElementRef;
  @ViewChildren('mobileMenuView') mobileMenuView: QueryList<ElementRef>;

  constructor(private authService: AuthService, private eref: ElementRef) {}

  logOut(): void {
    this.authService.logout('manual');
  }

  toggle() {
    this.toggleMenu = !this.toggleMenu;
  }

  menuItemSelect() {
    this.toggleMenu = false;
  }

  // Hide Menu
  onClick(event: MouseEvent) {
    if (!this.eref.nativeElement.contains(event.target))
      // or some similar check
      this.toggleMenu = false;
  }

  // Hide Menu
  onPan() {
    this.toggleMenu = false;
  }
}
