/* eslint-disable no-restricted-syntax */
import {
  Component,
  OnDestroy,
  Inject,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  AfterContentInit
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

import * as fromStore from 'src/app/store/';
import { TicketService } from 'src/app/services/ticket.service';
import { OrderService } from 'src/app/services/order.service';
import { ContentDocumentLink } from 'src/app/models/content-document-link.model';
import { Order } from 'src/app/models/order.model';
import { Service } from 'src/app/models/service.model';
import { User } from 'src/app/models/user.model';

@Component({
  selector: 'app-create-ticket',
  templateUrl: './create-ticket.component.html',
  styleUrls: ['./create-ticket.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateTicketComponent implements AfterContentInit, OnDestroy {
  browserSize: string;
  dialogWidth: string;
  dialogHeight: string;
  isLoading = true;
  billingPerm = this.authService?.apiAccount?.user?.Permissions?.includes('Invoice and Billing: Read');
  servicePerm = this.authService?.apiAccount?.user?.Permissions?.includes('Services');
  ordersPerm = this.authService?.apiAccount?.user?.Permissions?.includes('Orders');

  ticketFormGroup: UntypedFormGroup = this.fb.group({
    supportType: this.fb.control('', Validators.required)
  });

  formSubmitAttempted = false;
  inlineErrors: any = {};
  ticketChoiceMade = false;
  ticketSubmitted = false;
  createdOrder: Order = null;

  errorMessage: string = null;
  hasSubmissionErrorSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  hasSubmissionError$ = this.hasSubmissionErrorSubject.asObservable();

  isSubmittingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isSubmitting$ = this.isSubmittingSubject.asObservable();

  invoices$: Observable<ContentDocumentLink[]> = this.store.select(fromStore.getAllInvoiceAttachments);
  areInvoicesLoading$: Observable<boolean> = this.store.select(fromStore.getInvoiceAttachmentsLoading);
  areInvoicesLoaded$: Observable<boolean> = this.store.select(fromStore.getInvoiceAttachmentsLoaded);

  services$: Observable<Service[]> = this.store.select(fromStore.getServicesInService);
  areServicesLoading$: Observable<boolean> = this.store.select(fromStore.getServicesLoading);
  areServicesLoaded$: Observable<boolean> = this.store.select(fromStore.getServicesLoaded);
  selectedServiceId: string;
  showTicketOptions = false;

  contacts$: Observable<User[]> = this.store.select(fromStore.getContacts);
  disconnectReasons$: Observable<string[]> = this.store.select(fromStore.getDisconnectReasons);
  invoiceSubscription: Subscription;
  ticketSubscription: Subscription;
  servicesSubscription: Subscription;

  servicesObserver = {
    next: (services) => {
      if (services && services.length > 0) {
        this.showTicketOptions = true;
        this.changeDetectorRef.detectChanges();
      } else{
        this.showTicketOptions ? null : this.showTicketOptions = false;
        this.changeDetectorRef.detectChanges();
      }
    }, 
  }

  constructor(
    public dialogRef: MatDialogRef<CreateTicketComponent>,
    private store: Store<fromStore.State>,
    private ticketService: TicketService,
    private orderService: OrderService,
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) private data
  ) {
    this.dialogRef.disableClose = true;
    this.browserSize = this.data.browserSize;
    this.dialogWidth = this.browserSize === 'small' ? 'calc(100vw - 20px)' : '650px';
    this.dialogHeight = this.browserSize === 'small' ? '315px' : '380px';
  }

  ngOnInit(){
    this.isLoading = true;
  }

  ngAfterContentInit() {
    if (this.data.originPage) {
      this.ticketFormGroup.controls.supportType.setValue(this.data.originPage);
    }

    this.getInvoices();
    this.getServices();
    this.store.dispatch(new fromStore.LoadContacts());
    this.store.dispatch(new fromStore.LoadDisconnectReasons());

    this.selectedServiceId = this.data?.selectedServiceId || null;
  }

  ngAfterViewInit(){
    this.isLoading = false;
  }

  ngOnDestroy() {
    this.invoiceSubscription.unsubscribe();
    this.servicesSubscription.unsubscribe();
    if (this.ticketSubscription) {
      this.ticketSubscription.unsubscribe();
    }
  }

  getInvoices() {
    this.store.dispatch(new fromStore.LoadInvoiceAttachments());
    this.invoiceSubscription = this.invoices$.subscribe((invoices) => {
      if (invoices && invoices.length > 0) {
        this.showTicketOptions = true;
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  getServices() {
    this.store.dispatch(new fromStore.LoadServices());
    this.servicesSubscription = this.services$.subscribe(this.servicesObserver);
  }

  makeTicketSelection() {
    this.formSubmitAttempted = true;
    this.validateFields();
    if (this.ticketFormGroup.value.supportType !== '') {
      this.ticketChoiceMade = true;
      this.dialogRef.updateSize(this.dialogWidth, 'calc(100vh - 20px)');
    }
    return false;
  }

  canSubmit = () => {
    return true;
  };

  validateFields() {
    for (const prop in this.ticketFormGroup.controls) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.ticketFormGroup.controls.hasOwnProperty(prop)) {
        const control: any = this.ticketFormGroup.controls[prop];
        this.inlineErrors[prop] = control.valid ? null : 'This is a required field';
      }
    }
  }

  submitTicket() {
    this.ticketSubmitted = true;
    this.dialogRef.updateSize(this.dialogWidth, '550px');
  }

  submitNocForm(request) {
    this.isSubmittingSubject.next(true);

    this.ticketSubscription = this.ticketService.createNocTicket(request).subscribe(
      () => {
        this.isSubmittingSubject.next(false);
        this.hasSubmissionErrorSubject.next(false);
        setTimeout(() => {
          this.store.dispatch(new fromStore.ClearTickets());
          this.store.dispatch(new fromStore.LoadTickets());
        }, 1000);
        this.submitTicket();
        this.changeDetectorRef.detectChanges();
      },
      (error) => {
        this.hasSubmissionErrorSubject.next(true);
        this.isSubmittingSubject.next(false);
        this.GetErrorMessage(error);
        this.changeDetectorRef.detectChanges();
      }
    );
  }

  submitCareForm(request) {
    this.isSubmittingSubject.next(true);

    this.ticketSubscription = this.ticketService.createCareTicket(request).subscribe(
      () => {
        this.isSubmittingSubject.next(false);
        this.hasSubmissionErrorSubject.next(false);
        setTimeout(() => {
          this.store.dispatch(new fromStore.ClearTickets());
          this.store.dispatch(new fromStore.LoadTickets());
        }, 1000);
        this.submitTicket();
        this.changeDetectorRef.detectChanges();
      },
      (error) => {
        this.hasSubmissionErrorSubject.next(true);
        this.isSubmittingSubject.next(false);
        this.GetErrorMessage(error);
        this.changeDetectorRef.detectChanges();
      }
    );
  }

  submitBillForm(request) {
    this.isSubmittingSubject.next(true);

    this.ticketSubscription = this.ticketService.createBillingTicket(request).subscribe(
      () => {
        this.isSubmittingSubject.next(false);
        this.hasSubmissionErrorSubject.next(false);
        setTimeout(() => {
          this.store.dispatch(new fromStore.ClearTickets());
          this.store.dispatch(new fromStore.LoadTickets());
        }, 1000);
        this.submitTicket();
        this.changeDetectorRef.detectChanges();
      },
      (error) => {
        this.hasSubmissionErrorSubject.next(true);
        this.isSubmittingSubject.next(false);
        this.GetErrorMessage(error);
        this.changeDetectorRef.detectChanges();
      }
    );
  }

  submitOrderForm(request) {
    this.isSubmittingSubject.next(true);

    this.ticketSubscription = this.orderService.createOrder(request).subscribe(
      (data) => {
        this.isSubmittingSubject.next(false);
        this.hasSubmissionErrorSubject.next(false);
        this.createdOrder = data;
        this.store.dispatch(new fromStore.ClearOrders());
        this.store.dispatch(new fromStore.LoadOrders());
        this.submitTicket();
        this.changeDetectorRef.detectChanges();
      },
      (error) => {
        this.hasSubmissionErrorSubject.next(true);
        this.isSubmittingSubject.next(false);
        this.GetErrorMessage(error);
        this.changeDetectorRef.detectChanges();
      }
    );
  }

  private GetErrorMessage(error: any) {
    if (error.errors && Array.isArray(error.errors)) {
      this.errorMessage = error.errors.join('\n');
    } else {
      this.errorMessage = error || 'Server Error: Unable to submit the ticket. Please try again!';
    }
  }

  closeDialog() {
    this.isSubmittingSubject.next(false);
    this.hasSubmissionErrorSubject.next(false);
    this.errorMessage = null;
    this.dialogRef.close();

    if (this.ticketSubscription) {
      this.ticketSubscription.unsubscribe();
    }
  }

  goBack() {
    this.ticketChoiceMade = false;
    this.isSubmittingSubject.next(false);
    this.hasSubmissionErrorSubject.next(false);
    this.errorMessage = null;
    this.dialogRef.updateSize(this.dialogWidth, this.dialogHeight);
  }
}
