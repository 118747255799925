<div class="line-values-modal">
  <div class="line-values-modal__header">
    <h1 class="line-values-modal__title">
      {{ data$()?.title }}
    </h1>

    <button
      mat-icon-button
      class="line-values-modal__close"
      (click)="dialogRef.close()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="line-values-modal__body">
    <div class="line-values-modal__chart">
      <canvas
        baseChart
        [type]="chartService$.lineChartType"
        [data]="chartService$.lineChartData$()"
        [options]="chartService$.lineChartOptions"
        [plugins]="chartService$.lineChartPlugins"
        (chartClick)="chartService$.chartClicked($event)"
      ></canvas>
    </div>
  </div>

  <!-- <div class="line-values-modal__footer">
    <a [routerLink]="data$()?.hyperlink">
      {{ data$()?.hyperlinkText || "View " + data$()?.title }}
    </a>
  </div> -->
</div>
