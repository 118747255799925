import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, inject } from '@angular/core';

@Component({
  selector: 'app-single-value-modal',
  templateUrl: './single-value-modal.component.html',
  styleUrls: ['./single-value-modal.component.scss']
})
export class SingleValueModalComponent {

  public readonly dialogRef = inject(MatDialogRef<SingleValueModalComponent>)
  public readonly data = inject(MAT_DIALOG_DATA)

}
