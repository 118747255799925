export interface Navigation {
  name: string;
  exact: boolean;
  permissions: string;
  showOverlay?: boolean;
  children?: Omit<Navigation, "children">[];
  link?: string;
}

export const nav: Navigation[] = [
  {
    link: "/billing",
    name: "Billing",
    exact: true,
    permissions: "Invoice and Billing: Read",
  },
];
export const nav2: Navigation[] = [
  {
    link: "/orders",
    name: "Orders",
    exact: true,
    permissions: "Orders",
  },
  {
    name: "Reports",
    exact: true,
    permissions: "Orders",
    children: [
      {
        link: "/operations-summary",
        name: "Operations Summary",
        exact: true,
        permissions: "",
      },
      {
        link: "/orders-dashboard",
        name: "Orders Dashboard",
        exact: true,
        permissions: "Orders",
      },
    ],
  },
  {
    link: "/services",
    name: "Services",
    exact: true,
    permissions: "Services",
  },
  {
    link: "/tickets",
    name: "Tickets",
    exact: true,
    permissions: "Ticketing: Read",
  },
];

export const nav3: Navigation[] = [
  {
    link: "/orders",
    name: "Nitel IQ: Unusual Network Metrics",
    exact: true,
    permissions: "Nitel IQ Participant",
  },
  {
    link: "/orders",
    name: "Nitel IQ: Metric Details",
    exact: true,
    permissions: "Nitel IQ Participant",
  },
  {
    link: "/operations-summary",
    name: "Operations Summary Report",
    exact: true,
    permissions: "",
  },
  {
    link: "/orders",
    name: "Orders",
    exact: true,
    permissions: "Orders",
  },
  {
    link: "/orders-dashboard",
    name: "Orders Summary Report",
    exact: true,
    permissions: "Orders",
  },
  {
    link: "/services",
    name: "Services",
    exact: true,
    permissions: "Services",
  },
  {
    link: "/tickets",
    name: "Tickets",
    exact: true,
    permissions: "Ticketing: Read",
  },
];
